<template>
  <div class="card">
    <typography
      is="h3"
      :mode="typographyMode.title_participateCards"
      font="Balkara Condensed"
      class="title"
    >
      <slot name="title"></slot>
    </typography>
    <typography is="span" class="description" :font="fonts.jostVariable" :mode="typographyMode.mainText_ParticipateCards">
      <slot name="description"></slot>
    </typography>
  </div>
</template>

<script>
import Typography from '@/components/Primitives/Typography'
import { typographyMode } from '@/themes/primitivesModes'
import fonts from '@/themes/fonts'

export default {
  name: 'WayCard',
  components: {
    Typography
  },
  data () {
    return {
      fonts,
      typographyMode
    }
  }
}

</script>

<style lang="scss" scoped>
  .card {
    width: 360px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;

    & h3:first-of-type{
      margin-bottom: 8px;
    }

  }

  @media (max-width: 1919px) {
    .card {
      width: 300px;
    }
    .description {
      width: 270px;
    }
  }

  @media (max-width: 1132px) {
    .card {
      width: 220px;
      position: relative;

      &:before {
        position: absolute;
        inset: 0;
        z-index: 2;
        content: '';
        background: #7A7A7A;
        opacity: 0.5;
        filter: blur(42.5px);
      }

      .title {
        width: inherit;
        position: relative;
        z-index: 10;
      }

      .description {
        width: inherit;
        position: relative;
        z-index: 10;
      }
    }
  }

  @media (max-width: 743px) {
    .card {
      width: 200px;

      &:before {
        opacity: 0;
      }

      .title {
        width: inherit;
      }

      .description {
        width: 200px;
        line-height: 23px;
      }
    }
    .card:nth-child(2) {
      .description {
        width: 190px;
      }
    }
  }

</style>
