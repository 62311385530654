<template>
  <div class="modal-container">
    <div class="modal">
      <div class="cross-button" @click="$emit('closeModal')">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.75 2.0125L15.9875 0.25L9 7.2375L2.0125 0.25L0.25 2.0125L7.2375 9L0.25 15.9875L2.0125 17.75L9 10.7625L15.9875 17.75L17.75 15.9875L10.7625 9L17.75 2.0125Z"
            fill="#FAFFF6" />
        </svg>
      </div>
      <div class="top">
        <div class="title">
          <Typography is="h2" :mode="typographyMode.title_slide" :font="fonts.balkaraCondensed" color="#FFEECC"
            >Добро пожаловать<br />На проектную исповедь</Typography
          >
        </div>
        <div class="description">
          <div class="paragraph">
            <Typography is="p" :mode="typographyMode.mainText_slide"
              >Однажды Змей Горыныч наслал на Тридевятое царство проклятье горящих проектов, а некогда процветающее
              царство пришло в упадок. Царь просит вас победить Змея, живущего за дремучим лесом проектной деятельности.
            </Typography>
            <img
              src="https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-1.png"
              v-if="type !== 'mobile-xl' && type !== 'mobile-xs'" />
            <img
              src="https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-1-mobile.png"
              v-else />
          </div>
          <div class="paragraph">
            <img src="https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-2.png" />
            <Typography is="p" :mode="typographyMode.mainText_slide"
              >В этом лесу обитают Хранители, которые поделятся с вами тайнами проектного мастерства. Если всё получится
              — вы сможете победить горящие проекты раз и навсегда.
            </Typography>
          </div>
        </div>
      </div>
      <ButtonPrimitive @click="$emit('closeModal')">Начать приключение</ButtonPrimitive>
    </div>
  </div>
</template>
<script>
import Typography from '@/components/Primitives/Typography'
import ButtonPrimitive from '@/components/Primitives/Button'
import { typographyMode } from '@/themes/primitivesModes'
import { useBreakpoints } from '@/utils/useBreakpoints'
import fonts from '@/themes/fonts'
export default {
  name: 'WelcomePopUp',
  components: { Typography, ButtonPrimitive },
  data: function () {
    return {
      typographyMode,
      fonts
    }
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  }
}
</script>
<style lang="scss" scoped>
.modal-container {
  width: 100%;
  height: 100vh;
  background: rgba(16, 16, 16, 0.7);
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
}
.modal {
  margin-top: 100px;
  box-sizing: border-box;
  width: 960px;
  height: 800px;
  position: relative;
  padding: 60px 55px;
  background-image: url('https://storage.yandexcloud.net/cg-project-confession/modal/welcome-popup-desktop.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @include mobile{
    margin-bottom: 140px;
  }
  .button {
    padding-left: 77px;
    padding-right: 77px;
  }
}
.cross-button {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.title {
  margin-bottom: 48px;
}
.description {
  display: flex;
  flex-direction: column;
  gap: 35px;
  .paragraph {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    gap: 40px;
    .typography {
      max-width: 505px;
    }
  }
}
@media (max-width: 1919px) {
  .modal {
    margin-top: 80px;
    width: 795px;
    height: 639px;
    padding: 40px 20px;
  }
  .title {
    margin-bottom: 20px;
  }
  .description {
    gap: 20px;
    .paragraph {
      img {
        height: 170px;
      }
    }
  }
}
@media (max-width: 1132px) {
  .modal {
    width: 620px;
    height: 720px;
    background-image: url('https://storage.yandexcloud.net/cg-project-confession/modal/welcome-popup-tablet-v.png');
    padding-bottom: 50px;
  }
  .title {
    margin-bottom: 40px;
  }
  .description {
    gap: 40px;
    .paragraph {
      gap: 20px;
      p {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
      }
      img {
        height: 170px;
      }
    }
  }
  @media (max-width: 743px) {
    .modal {
      width: 320px;
      height: 560px;
      padding: 30px 10px;
      background-image: url('https://storage.yandexcloud.net/cg-project-confession/modal/welcome-popup-mobile.png');
      align-items: normal;
      .button {
        font-size: 14px;
        line-height: 18px;
        padding: 16px 60px;
      }
    }
    .title {
      margin-bottom: 20px;
      .typography {
        font-size: 24px;
        line-height: 29px;
      }
    }
    .description {
      gap: 20px;
      .paragraph {
        text-align: center;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        img {
          height: 130px;
        }
        .typography {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .paragraph:last-child {
        img {
          display: none;
        }
      }
    }
    .cross-button {
      top: 14px;
      right: 14px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>
