<template>
  <div class="village" ref="village">
    <FireGif
      v-for="fire in fires"
      :key="fire.class"
      ref="fires"
      :isStatic="fire.isStatic"
      :class="`fire ${fire.class}`"
    />
    <TreeGif
      v-for="tree in trees"
      :key="tree.class"
      ref="trees"
      :isStatic="tree.isStatic"
      :class="`tree ${tree.class}`"
    />
  </div>
</template>

<script>
import FireGif from '@/components/sections/Serpent/FireGif'
import TreeGif from '@/components/sections/Serpent/TreeGif'

export default {
  name: 'VillageComponent',
  components: {
    FireGif,
    TreeGif
  },
  data () {
    return {
      trees: [
        { class: 'one', isStatic: true },
        { class: 'two', isStatic: true },
        { class: 'three', isStatic: true },
        { class: 'four', isStatic: true },
        { class: 'five', isStatic: true },
        { class: 'six', isStatic: true },
        { class: 'seven', isStatic: true }
      ],
      fires: [
        { class: 'tower', isStatic: true },
        { class: 'tower-left', isStatic: true },
        { class: 'tower-right', isStatic: true },
        { class: 'center-left', isStatic: true },
        { class: 'center-right', isStatic: true },
        { class: 'right-side-left', isStatic: true },
        { class: 'right-side-right', isStatic: true }
      ],
      delay: 200
    }
  },
  methods: {
    fireBegin () {
      this.$refs.fires.forEach((item, index) => {
        setTimeout(() => {
          this.fires[index].isStatic = false
        }, this.delay * index)
      })
    },
    treeBegin () {
      this.$refs.trees.forEach((item, index) => {
        setTimeout(() => {
          this.trees[index].isStatic = false
        }, this.delay * index)
      })
    }
  },

  mounted () {
    this.fireBegin()
    this.treeBegin()
  }

}
</script>

<style lang="scss" scoped>
.village {
  position: absolute;
  top: 0;
  width: 2048px;
  z-index: 12;
  height: 2000px;
  background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-village-desktop");
}

.tree {
  position: absolute;
  display: block;

  &.one {
    top: 1100px;
    left: 300px;
  }
  &.two {
    top: 1500px;
    left: 200px;
    width: 100px;
    height: 100px;
  }
  &.three {
    top: 1438px;
    left: 594px;
    width: 110px;
    height: 70px;
  }
  &.four {
    top: 1176px;
    right: 620px;
    width: 110px;
    height: 50px;
  }
  &.five {
    top: 1468px;
    right: 64px;
    width: 250px;
    height: 200px;
  }
  &.six {
    top: 1000px;
    right: 224px;
    width: 150px;
    height: 100px;
  }
  &.seven {
    top: 1500px;
    left: 594px;
    width: 200px;
    height: 200px;
  }
}

.fire {
  position: absolute;
  display: block;
  &.tower {
    top: 882px;
    left: 203px;
  }
  &.tower-left{
    top: 1159px;
    left: 209px;
    width: 150px;
    height: 200px;
  }
  &.tower-right{
    top: 1360px;
    left: 273px;
    width: 100px;
    height: 150px;
  }
  &.center-left{
    top: 1015px;
    left: 533px;
    width: 250px;
    height: 300px;
  }
  &.center-right{
    top: 1407px;
    left: 692px;
    width: 100px;
    height: 150px;
  }
  &.right-side-left{
    top: 996px;
    left: auto;
    right: 134px;
    width: 150px;
    height: 300px;
  }
  &.right-side-right{
    top: 1258px;
    left: auto;
    right: 158px;
    width: 110px;
    height: 150px;
  }
}

@media (max-width: 1919px) {
    .village {
      background-repeat: no-repeat;
      background-position: center;
      top: 766px;
      z-index: 10;
      width: 2048px;
      height: 2392px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-village-tablet");
  }

  .tree {
    position: absolute;
    display: block;

    &.one {
      top: 320px;
      left: 280px;
    }
    &.two {
      top: 500px;
      left: 500px;
    }
    &.three {
      top: 800px;
      left: 580px;
      width: 250px;
      height: 200px;
    }
    &.four {
      top: 876px;
      right: 620px;
    }
    &.five {
      top: 380px;
      right: 300px;
    }
    &.six {
      top: 876px;
      right: 920px;
      width: 150px;
      height: 100px;
    }
    &.seven {
      top: 900px;
      left: 1200px;
      width: 200px;
      height: 200px;
    }
  }

  .fire {
    position: absolute;
    display: block;
    &.tower {
      top: 251px;
      left: 389px;
    }
    &.tower-left{
      top: 652px;
      left: 424px;
    }
    &.tower-right{
      display: none;
    }
    &.center-left{
      top: 267px;
      left: 637px;
    }
    &.center-right{
      top: 764px;
      left: 720px;
    }
    &.right-side-left{
      display: none;
    }
    &.right-side-right{
      top: 204px;
      right: 480px;
    }
  }

  @media (max-width: 1132px) {
    .village {
      top: 600px;
      z-index: 11;
      height: 1571px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-village-tablet-vertical");
    }
    .tree {
      position: absolute;
      display: block;

      &.one {
        top: 320px;
        left: 20px;
      }
      &.two {
        top: 420px;
        left: 100px;
      }
      &.three {
        top: 300px;
        right: 10px;
      }
      &.four {
        display: none;
      }
      &.five {
        display: none;
      }
      &.six {
        top: 250px;
        right: 10px;
        width: 150px;
        height: 100px;
      }
      &.seven {
        display: none;
      }
    }

    .fire {
      position: absolute;
      display: block;
      &.tower {
        width: 100px;
        top: 231px;
        left: 8px;
      }
      &.tower-left{
        display: none;
      }
      &.tower-right{
        width: 60px;
        height: 100px;
        top: 442px;
        left: 32px;
        display: block;
      }
      &.center-left{
        width: 70px;
        height: 100px;
        top: 209px;
        left: 137px;
      }
      &.right-side-left{
        display: block;
        right: 21px;
        width: 60px;
        height: 100px;
        top: 295px;
      }
      &.right-side-right{
        right: 39px;
        width: 40px;
        height: 70px;
        left: auto;
        top: 564px;
      }
    }
  }

  @media (max-width: 743px) {
    .village {
      height: 1024px;
      width: 430px;
      top: 480px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-village-phone");
    }
    .fire {
      display: none !important;
    }
    .tree {
      position: absolute;
      display: block;

      &.one {
        top: 426px;
        left: -79px;
      }
      &.two {
        top: 464px;
        left: auto;
        right: -19px;
      }
      &.three {
        top: 706px;
        left: -105px;
      }
      &.four {
        display: block;
        top: 624px;
        right: -5px;
      }
      &.five {
        display: block;
        top: 700px;
        width: 80px;
        height: 80px;
        right: -5px;
      }
      &.six {
        top: 800px;
        right: -20px;
        width: 150px;
        height: 100px;
      }
      &.seven {
        display: block;
        top: 611px;
        left: -13px;
        width: 80px;
        height: 80px;
      }
    }
  }

}
</style>
