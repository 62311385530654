<template>
  <h2>
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    content: String
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    text-align: left;
    font-family: 'Jost Variable';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 4rem;
    text-transform: uppercase;
    color: #FFEECC;
  }
  @media(max-width: 1132px) {
    h2 {
      line-height: 39px;
      font-size: 1.8rem;
    }
  }

  @media(max-width: 743px) {
    h2 {
      line-height: 31px;
      font-size: 1.5rem;
    }
  }

  @media(max-width: 730px) {
    h2 {
      font-size: 1.5rem;
    }
  }
</style>
