<template>
  <div class="title-block" id="map-heading">
    <div class="title-wrapper">
      <Typography is="h2" :mode="typographyMode.title_map_participate" :font="fonts.jostVariable" class="title-block-text">Карта Проектной Исповеди...</Typography>
      <Typography is="h2" :mode="typographyMode.title_map_participate" :font="fonts.jostVariable" class="title-block-text">и дремучего леса</Typography>
    </div>
    <Typography is="p" :mode="typographyMode.mainText_map" class="title-block-text" >
      Хранители 6 сессий раскроют свои секреты и дадут волшебные предметы, чтобы победить Змея Горыныча.
    </Typography>
  </div>
</template>

<script>
import Typography from '@/components/Primitives/Typography'
import { typographyMode } from '@/themes/primitivesModes'
import fonts from '@/themes/fonts'

export default {
  name: 'MapTitleBlock',
  components: {
    Typography
  },
  data: function () {
    return {
      typographyMode, fonts
    }
  }
}
</script>

<style lang="scss" scoped>
 .title-block {
   position: relative;
   height: 360px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
   -webkit-align-items: center;
   flex-flow: column;
   align-items: center;

   & p {
     width: 660px;
     text-align: start;
     margin-left: 460px;
   }

 }

 .title-wrapper .title-block-text{
   color: #FFEECC;
 }

 .title-wrapper{
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
   flex-flow: column;
   width: 980px;
   margin-right: 230px;
   margin-top: 146px;

   & h2:first-of-type{
     -webkit-align-self: flex-start;
     align-self: flex-start;
   }

   & h2:last-of-type{
     -webkit-align-self: flex-end;
     align-self: flex-end;
   }
 }

 .anchor{
   position: absolute;
   top: 700px;
   width: 0;
   height: 0;
 }

 @media (max-width: 1919px) {
   .title-block-text,
   .title-wrapper .title-block-text{
     color: $black;
   }

   .title-block{
     & p {
       width: 509px;
     }
   }

   .title-wrapper{
     width: 940px;
     margin-top: 110px;
      & h2:first-of-type{
        margin-left: 45px;
      }
   }

   .anchor{
     top: 550px;
   }
 }

 @media (max-width: 1500px) {
   .anchor{
     top: 450px;
   }
 }

 @media (max-width: 1132px) {
   .title-block-text{
     color: $black;
   }

   .title-block{
     & p {
       width: 430px;
       margin-left: 135px;
     }
   }

   .title-wrapper {
     width: 525px;

     & h2:first-of-type {
       margin-left: 35px;
     }

     & h2:last-of-type {
       margin-right: 45px;
     }
   }
 }
 @media (max-width: 743px) {
   .text-wrapper{
     width: 412px;
   }

   .title-wrapper{
     margin-right:170px;
     margin-top: 98px;

     & h2:first-of-type {
       margin-left: 196px;
       text-align: start;
       color: #FFEECC;
     }

     & h2:last-of-type {
       margin-right: 100px;
       color: #FFEECC;
     }
   }

   .title-block p{
     color: $white;
     width: 280px;
     margin-left: -20px;
   }
   .anchor{
     top: 370px;
   }

 }

 @media (max-width: 375px) {
  .anchor {
    top: 200px;
  }
 }
</style>
