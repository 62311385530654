<template>
  <div class="parallax-container">
    <Serpent />
    <ProjectManagement />
    <Statistics />
  </div>
</template>

<script>
import ProjectManagement from '@/components/sections/ProjectManagement'
import Serpent from '@/components/sections/Serpent/Serpent'
import Statistics from '@/components/sections/Statistics'

export default {
  name: 'SerpentParallax',
  components: {
    ProjectManagement,
    Serpent,
    Statistics
  }
}
</script>

<style scoped>
  .parallax-container {
    width: 100%;
    overflow: hidden;
  }
</style>
