const imagesAbout = {
  desktop: [
    'https://storage.yandexcloud.net/cg-project-confession/map-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-village-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-bg-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-left-rock-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-right-rock-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-wood-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-serpent-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-right-cloud-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-left-cloud-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-hero-desktop',
    'https://storage.yandexcloud.net/cg-project-confession/fire-png',
    'https://storage.yandexcloud.net/cg-project-confession/fire-gif',
    'https://storage.yandexcloud.net/cg-project-confession/tree-png'
  ],

  'tablet-h': [
    'https://storage.yandexcloud.net/cg-project-confession/parallax-bg-tablet',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-hero-tablet',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-clouds-tablet',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-serpent-tablet',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-wood-tablet',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-right-rock-tablet',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-left-rock-tablet',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-village-tablet',
    'https://storage.yandexcloud.net/cg-project-confession/map-tablet-horizontal',
    'https://storage.yandexcloud.net/cg-project-confession/fire-png',
    'https://storage.yandexcloud.net/cg-project-confession/fire-gif',
    'https://storage.yandexcloud.net/cg-project-confession/tree-png',
    'https://storage.yandexcloud.net/cg-project-confession/tree-gif'
  ],
  'tablet-v': [
    'https://storage.yandexcloud.net/cg-project-confession/parallax-village-tablet-vertical',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-left-rock-tablet-vertical',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-right-rock-tablet-vertical',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-wood-tablet-vertical',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-serpent-tablet-vertical',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-clouds-tablet-vertical',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-hero-tablet-vertical',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-bg-tablet-vertical',
    'https://storage.yandexcloud.net/cg-project-confession/map-tablet-vertical',
    'https://storage.yandexcloud.net/cg-project-confession/fire-png',
    'https://storage.yandexcloud.net/cg-project-confession/fire-gif',
    'https://storage.yandexcloud.net/cg-project-confession/tree-png',
    'https://storage.yandexcloud.net/cg-project-confession/tree-gif'
  ],
  'mobile-xl': [
    'https://storage.yandexcloud.net/cg-project-confession/parallax-left-rock-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-right-rock-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-wood-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-serpent-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-clouds-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-hero-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-bg-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-village-phone',
    'https://storage.yandexcloud.net/cg-project-confession/map-phone',
    'https://storage.yandexcloud.net/cg-project-confession/fire-png',
    'https://storage.yandexcloud.net/cg-project-confession/fire-gif',
    'https://storage.yandexcloud.net/cg-project-confession/tree-png',
    'https://storage.yandexcloud.net/cg-project-confession/tree-gif'
  ],
  'mobile-xs': [
    'https://storage.yandexcloud.net/cg-project-confession/parallax-left-rock-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-right-rock-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-wood-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-serpent-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-clouds-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-hero-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-bg-phone',
    'https://storage.yandexcloud.net/cg-project-confession/parallax-village-phone',
    'https://storage.yandexcloud.net/cg-project-confession/map-phone',
    'https://storage.yandexcloud.net/cg-project-confession/fire-png',
    'https://storage.yandexcloud.net/cg-project-confession/fire-gif',
    'https://storage.yandexcloud.net/cg-project-confession/tree-png',
    'https://storage.yandexcloud.net/cg-project-confession/tree-gif'
  ]
}

export default imagesAbout
