<template>
  <img v-if="isStatic" src="https://storage.yandexcloud.net/cg-project-confession/fire-png" alt="огонь"/>
  <img v-else src="https://storage.yandexcloud.net/cg-project-confession/fire-gif" alt="огонь"/>
</template>

<script>
export default {
  name: 'FireGif',
  props: {
    isStatic: Boolean
  }
}
</script>

<style>

</style>
