<template>
  <div class="scroll-button custom-cursor" :class="{show: isVisible}" @click="scrollToTop"
       v-if="type !==
  'mobile-xl' &&
  type !== 'mobile-xs'" />
</template>
<script>
import { useBreakpoints } from '@/utils/useBreakpoints'

export default {
  name: 'ScrollToTopButton',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  }
}
</script>
<style lang="scss" scoped>
.scroll-button {
  z-index: 11;
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 50px;
  height: 50px;
  background-image: url('@/assets/img/scroll-to-top-big.png');
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  pointer-events: none;
  &.show{
    opacity: 1;
    pointer-events: auto;
  }

  @media (max-width: 1133px) {
    width: 40px;
    height: 40px;
    background-image: url('@/assets/img/scroll-to-top-small.png');
  }
}
</style>
