<template>
  <img v-if="isStatic" src="https://storage.yandexcloud.net/cg-project-confession/tree-png" alt="дерево">
  <img v-else src="https://storage.yandexcloud.net/cg-project-confession/tree-gif"  alt="дерево">
</template>

<script>
export default {
  name: 'TreeGif',
  props: {
    isStatic: Boolean
  }
}
</script>

<style>

</style>
