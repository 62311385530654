<template>
  <div class="title">
    <section-title v-for="(line, index) in lines" :key="index" class="title__line">
      {{ line }}
    </section-title>
  </div>
</template>

<script>
import SectionTitle from '@/components/Primitives/SectionTitle'

export default {
  name: 'MultilineTitle',
  components: {
    SectionTitle
  },
  props: {
    lines: Array
  }
}
</script>

<style lang="scss" scoped>
  .title {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;

    &__line {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      overflow-wrap: break-word;
    }

    &__line:not(:first-child){
      width: 50%;
      -webkit-align-self: flex-end;
      align-self: flex-end;
    }
  }

  @media(max-width: 1132px) {
    .title {
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      word-break: keep-all;
      &__line:not(:first-child){
        margin-left: 12px;
        width: auto;
        -webkit-align-self: center;
        align-self: center;
      }
    }
  }

  @media(max-width: 743px) {
    .title {
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      width: 310px;
      flex-wrap: nowrap;
      &__line:first-child{
        width: 200px;
      }
      &__line:not(:first-child){
        bottom: 0;
        right: 10px;
        margin-left: 0;
        position: absolute;
        word-break: keep-all;
        width: auto;
      }
    }
  }
</style>
