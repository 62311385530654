import { computed, onMounted, onUnmounted, ref } from 'vue'

export const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth)

  // eslint-disable-next-line no-return-assign
  const onWidthChange = () => windowWidth.value = window.innerWidth
  onMounted(() => window.addEventListener('resize', onWidthChange))
  onUnmounted(() => window.removeEventListener('resize', onWidthChange))

  const type = computed(() => {
    if (windowWidth.value >= 1920) return 'desktop'
    if (windowWidth.value >= 1133 && windowWidth.value < 1920) return 'tablet-h'
    if (windowWidth.value >= 744 && windowWidth.value < 1133) return 'tablet-v'
    if (windowWidth.value >= 430 && windowWidth.value < 744) return 'mobile-xl'
    if (windowWidth.value < 430) return 'mobile-xs'
    return null
  })

  return type
}
