<template>
  <div class="header">
    <div class="flex-container">
      <router-link to="/" @keydown.enter="moveToTop" class="logo-wrapper custom-cursor">
        <img @click="moveToTop"  src="@/assets/img/Logo.png" alt="Ростелеком" class="logo" >
      </router-link>
      <div class="nav">
        <ul class="navbar">
          <navbar-item route="/">программа</navbar-item>
          <navbar-item route="/game">игра</navbar-item>
          <navbar-item route="/tale">сказ</navbar-item>
          <navbar-item route="/about">об исповеди</navbar-item>
        </ul>
        <div class="burger-container custom-cursor" @click="showMenu" ref="burger">
          <button class="burger-button custom-cursor"></button>
        </div>
      </div>
    </div>
    <nav class="hidden-navbar" ref="menu">
      <router-link to="/" @keydown.enter="moveToTop" class="logo-wrapper custom-cursor">
        <img
          @click="moveToTop"
          src="@/assets/img/big-logo.png"
          alt="Ростелеком"
          class="hidden-navbar__logo custom-cursor"
        >
      </router-link>
      <div class="menu-container">
        <ul class="hidden-menu">
          <navbar-item route="/" class="hidden-menu__item" @click="hideMenu">
            программа
          </navbar-item>
          <navbar-item route="/game" class="hidden-menu__item" @click="hideMenu">
            игра
          </navbar-item>
          <navbar-item route="/tale" class="hidden-menu__item" @click="hideMenu">
            сказ
          </navbar-item>
          <navbar-item route="/about" class="hidden-menu__item" @click="hideMenu">
            об исповеди
          </navbar-item>
        </ul>
      </div>
      <button class="cross-button custom-cursor" @click="hideMenu">
        <img src="@/assets/img/cross.png" alt="закрыть меню" class="cross">
      </button>
    </nav>
  </div>
</template>

<script>
import NavbarItem from '@/components/Primitives/NavbarItem'

export default {
  name: 'HeaderSection',
  components: {
    NavbarItem
  },
  methods: {
    handleClick () {
      this.$router.push('/')
      setTimeout(() => {
        document.querySelector('#reg').scrollIntoView({ behavior: 'smooth' })
      }, 0)
    },
    moveToAnchor () {
      this.handleClick()
      this.hideMenu()
    },
    showMenu () {
      this.$refs.menu.classList.remove('hide-menu')
      this.$refs.menu.classList.add('show-menu')
    },
    hideMenu () {
      if (!this.$refs.menu.classList.contains('show-menu')) return
      this.$refs.menu.classList.remove('show-menu')
      this.$refs.menu.classList.add('hide-menu')
    },
    escapeTap (e) {
      if (!(e.key === 'Escape')) return
      this.hideMenu()
      this.$refs.burger.blur()
    },
    moveToTop () {
      this.hideMenu()
      document.querySelector('#page-top').scrollIntoView({ behavior: 'smooth' })
    }
  },

  mounted () {
    window.addEventListener('keydown', this.escapeTap)
  },

  beforeUnmount () {
    window.removeEventListener('keydown', this.escapeTap)
  }

}
</script>

<style lang="scss" scoped>
  .header {
    z-index: 10000000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    background: rgba(243, 255, 234, 0.05);
    backdrop-filter: blur(7.5px);

    .logo {
      width: 100px;
      height: 40px;
    }
  }

  .burger-container {
    padding: 15px 0;
  }

  .hidden-navbar {
    will-change: transform;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    right: -100%;
    height: 100vh;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    gap: 90px;
    -webkit-flex-direction: column;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('@/assets/img/hidden-menu-background.png');
  }

  .menu-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    gap: 40px;
  }

  .rostelecom-link {
    width: fit-content;
    display: block;
  }

  .hidden-navbar__logo {
    display: block;
    width: 200px;
    height: 80px;
  }

  .cross-button {
    display: block;
    padding: 10px;
    position: absolute;
    top: 18.33px;
    right: 68.34px;
    background: transparent;
    border: none;

    .cross {
      width: 23.33px;
      height: 23.33px;
    }
  }

  .show-menu {
    animation: show-menu .5s forwards ease-out;
  }

  .hide-menu {
    animation: hide-menu .5s forwards ease-out;
  }

  @keyframes show-menu {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  @keyframes hide-menu {
    0% {
      right: 0;
    }
    100% {
      right: -100%;
    }
  }

  .hidden-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    gap: 20px;
  }

  .hidden-reg-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 240px;
    height: 50px;
  }

  .flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 1160px;
  }

  .burger-button {
    display: none;
  }

  .reg-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    font-size: 18px;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    height: 45px;
    width: 160px;
  }

  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }

  .navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    margin-right: 89px;
    align-items: center;

    *{
      margin-right: 60px;
    }

    .reviews-item {
      margin-right: 0;
    }
  }

  @media (max-width: 1919px) {
    .reg-button {
      font-size: 16px;
    }
    .header {
      height: 60px;
    }
    .flex-container {
      max-width: 1013px;
    }
    .navbar {
      display: none;
    }

    .burger-container {
      margin-left: 94px;
    }

    .burger-button {
      border: none;
      position: relative;
      display: block;
      width: 30px;
      height: 3px;

      &:after, &:before {
        left: 0;
        right: 0;
        position: absolute;
        content: '';
        background: white;
        height: 3px;
        bottom: 8.5px;
      }

      &:after {
        bottom: -8.5px;
      }
    }
  }

  @media (max-width: 1132px) {
    .flex-container {
      max-width: 625px;
    }
    .burger-container {
      margin-left: 45px;
    }
    .show-menu {
      animation-duration: .3s;
    }

    .hide-menu {
      animation-duration: .3s;
    }
  }

  @media (max-width: 743px) {
    .reg-button {
      display: none;
    }
    .hidden-navbar {
      gap: 73px;
    }
    .flex-container {
      max-width: 300px;
    }
  }

</style>
