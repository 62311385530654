<template>
  <div class="map-section section">
    <MapTitleBlock />
    <MapMobileSchedule v-if="isMobile"></MapMobileSchedule>
    <template v-else>
      <MapScheduleBlock @changeSlide="changeSlide" :slide-index="slideIndex" />
      <MapSliderBlock :slide-index="slideIndex" @changeSlide="changeSlide" />
    </template>
  </div>
</template>

<script>
import MapTitleBlock from '@/components/sections/Map/MapTitleBlock'
import MapScheduleBlock from '@/components/sections/Map/MapScheduleBlock'
import MapSliderBlock from '@/components/sections/Map/MapSliderBlock'
import { useBreakpoints } from '@/utils/useBreakpoints'
import MapMobileSchedule from '@/components/sections/Map/MapMobileSchedule'

export default {
  name: 'MapSection',
  components: {
    MapMobileSchedule,
    MapSliderBlock,
    MapScheduleBlock,
    MapTitleBlock
  },
  data: function () {
    return {
      slideIndex: this.type === 'desktop' ? 1 : 0
    }
  },
  methods: {
    changeSlide (index) {
      this.slideIndex = index
    }
  },
  computed: {
    isMobile () {
      return this.type.includes('mobile')
    }
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  }
}
</script>

<style lang="scss">
  .map-section {
    height: 1429px;
    background-image: url("https://storage.yandexcloud.net/cg-project-confession/map-desktop");
  }
  @media (max-width: 1919px) {
    .map-section{
      max-width: 1440px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/map-tablet-horizontal");
      overflow-x: hidden;
      height: 1615px;
    }
  }
  @media (max-width: 1132px) {
    .map-section{
      max-width: 960px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/map-tablet-vertical");
      overflow-x: hidden;
      height: 2039px;
    }
  }
  @media (max-width: 743px) {
    .map-section{
      max-width: 430px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/map-phone");
      overflow-x: hidden;
      height: 4360px;
    }
  }
</style>
