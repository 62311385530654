<template>
  <div class="footer-section section">
    <div class="logos">
      <img
        src="@/assets/img/rostelecom-logo.png"
        alt="Ростелеком"
        class="rostelecom-logo"
      >
      <img
        src="@/assets/img/guild-logo.png"
        alt="Гильдия"
        class="guild-logo"
      >
    </div>
    <div class="info">
      <typography is="span" :mode="typographyMode.addText_footer_slideSpeakerInfo" class="rostelecom">
        2022 | Ростелеком
      </typography>
      <a href="#" @click.prevent="teleportToPrivacy" class="confidentiality">Политика конфиденциальности</a>
    </div>
  </div>
</template>

<script>
import Typography from '@/components/Primitives/Typography'
import { typographyMode } from '@/themes/primitivesModes'

export default {
  name: 'FooterSection',
  components: {
    Typography
  },
  data () {
    return {
      typographyMode
    }
  },
  methods: {
    teleportToPrivacy () {
      this.$router.push('privacy')
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer-section {
    position: relative;
    z-index: 10;
    overflow: hidden;
    box-sizing: border-box;
    padding-top: 50px;
    height: 210px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    background-color: #101010;
  }

  .logos, .info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .logos {
    width: 548px;
    margin-bottom: 53px;
  }

  .info {
    width: 1160px;
    padding-bottom: 10px;
  }

  .rostelecom-logo, .guild-logo {
    width: 194px;
    height: 80px;
  }

  .confidentiality {
    color: #FAFFF6;
    font-size: 14px;
    font-family: "Jost Variable";
    text-decoration: none;
  }

  @media (max-width: 1919px){
    .footer-section {
      height: 240px;
      padding-top: 70px;
    }
    .logos {
      width: 498px;
    }

    .info {
      width: 1013px;
      padding-bottom: 13px;
    }
  }

  @media (max-width: 1132px) {
    .footer-section {
      height: 267px;
      padding-top: 80px;
    }
    .logos {
      width: 458px;
    }
    .info {
      width: 620px;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 743px) {
    .footer-section {
      height: 390px;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
    }
    .logos {
      width: 211px;
      -webkit-flex-direction: column;
      flex-direction: column;
      margin-bottom: 100px;
    }
    .info {
      height: 44px;
      width: 195px;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-flex-direction: column;
      flex-direction: column;
      padding-bottom: 10px;
    }
  }
</style>
