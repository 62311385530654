<template>
  <div class="counter-data">
    <Typography
      is="h2"
      :mode="typographyMode.title_counterData"
      :font="fonts.balkaraCondensed"
      :color="colors.black"
      class="counter-number"
    >
      <slot name="number" />
      </Typography>
    <slot name="description" />
  </div>
</template>

<script>
import Typography from '@/components/Primitives/Typography'
import { typographyMode } from '@/themes/primitivesModes'
import colors from '@/themes/colors'
import fonts from '@/themes/fonts'

export default {
  name: 'CounterData',
  components: {
    Typography
  },
  data: function () {
    return { typographyMode, colors, fonts }
  }
}
</script>

<style lang="scss" scoped>
 .counter-data{
   width: 160px;
   height: 150px;
   position: relative;
   z-index: 10;

   &:after{
     position: absolute;
     display: block;
     content: '';
     width: 200px;
     height: 186px;
     top: -20px;
     background-color: rgba(250, 255, 246, 0.5);
     filter: blur(42px);
     z-index:-1;
   }
 }

 .counter-number {
   color: $red;
 }

 @media (max-width: 1919px) {
   .counter-data{
     width: 154px;
     height: 181px;

     &:after{
       width: 170px;
       height: 180px;
       background-color: rgba(250, 255, 246, 0.4);
     }
   }
 }

 @media (max-width: 1129px) {
   .counter-data {
     width: 140px;
     height: 115px;
   }
 }

 @media (max-width: 743px) {
   .counter-number{
     color: #FFEECC;
   }

   .counter-data{
     &:after{
       display: none;
     }
   }
 }
</style>
