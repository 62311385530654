import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PrivacyPolicy from '../views/PrivacyPolicyView.vue'

const routes = [
  {
    path: '/about',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'program',
    component: () => import('@/views/ProgramView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPolicy
  },
  {
    path: '/tale',
    name: 'tale-end',
    component: () => import('@/views/TaleViewRec.vue')
  },
  {
    path: '/game',
    name: 'game-end',
    component: () => import('@/views/GameViewRec.vue')
  },
  {
    path: '/tale-dii',
    name: 'tale-dii',
    component: () => import('@/views/TaleView.vue')
  },
  {
    path: '/game-dii',
    name: 'game-dii',
    component: () => import('@/views/GameView.vue')
  }
]

const router = createRouter({
  scrollBehavior (to, from) {
    return {
      top: 0
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
