<template>
  <div class="review-container">
    <typography is='p' class="content">
      <slot name="content"/>
    </typography>
    <div class="author-section">
      <typography is='span' color="#101010" class="author-text">
        <slot name="author"/>
      </typography>
    </div>
  </div>
</template>
<script>
import Typography from '@/components/Primitives/Typography'

export default {
  name: 'ReviewComponent',
  props: {
    content: String,
    author: String
  },
  components: {
    Typography
  }
}
</script>

<style lang="scss" scoped>
  .review-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .author-section {
     display: flex;
     height: 58px;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
     align-items: center;
     background-repeat: no-repeat;
     background-size: 100% 100%;
     background-image: url('https://storage.yandexcloud.net/cg-project-confession/paper-png');
  }

   .content {
     line-height: 29px;
     margin-bottom: 15px;
     text-align: left;
     margin-left: 29px;
     font-size: 22px;
   }

   @media(max-width: 1919px) {
     .content {
       margin-bottom: 18px;
       font-size: 18px;
       line-height: 23px;
     }
     .author-section {
       height: 45px;
     }
     .author-text {
       font-size: 18px;
     }
   }

  @media(max-width: 1132px) {
    .content {
      margin-bottom: 10px;
    }
  }
  @media(max-width: 743px) {
    .review-container {
      width: 280px;
    }
    .content {
      width: 280px !important;
      margin-left: 0;
      margin-bottom: 20px;
    }

    .author-text {
      text-align: left;
      line-height: 23px;
    }

    .author-section:nth-child(2) {
      box-sizing: border-box;
      padding-left: 30px;
    }

    .author-section {
      -webkit-align-self: flex-end;
      align-self: flex-end;
    }
  }
</style>
