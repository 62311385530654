<template>
  <div class="statistics-section section">
    <div class="statistics-counter-bg">
      <div class="statistics-counter">
        <CounterData v-for="cardData in textData" :key="cardData.description">
          <template v-slot:number>{{cardData.number}}</template>
          <template v-slot:description>
            <Typography
              is="p"
              :mode="typographyMode.mainText_counterData"
              :color="colors.black"
              class="counter-text"
              v-for="line in cardData.description"
              :key="line"
            >
              {{line}}
            </Typography>
          </template>
        </CounterData>
      </div>
    </div>
  </div>
</template>

<script>
import CounterData from '@/components/sections/Statistics/CounterData'
import Typography from '@/components/Primitives/Typography'
import { typographyMode } from '@/themes/primitivesModes'
import fonts from '@/themes/fonts'
import colors from '@/themes/colors'
export default {
  name: 'StatisticsSection',
  components: { CounterData, Typography },
  data () {
    return {
      textData: [
        { number: '10', description: ['откровенных выступлений'] },
        { number: '16', description: ['спикеров-хранителей'] },
        { number: '5', description: ['интерактивных форматов'] },
        { number: '2', description: ['пути участия', 'в конференции'] },
        { number: '4', description: ['часа', 'контента'] },
        { number: '5', description: ['актуальных', 'тем'] }
      ],
      typographyMode,
      fonts,
      colors
    }
  }
}
</script>

<style lang="scss">
  .statistics-section {
    position: relative;
    z-index: 10;
    min-height: auto !important;
    background-image: url("https://storage.yandexcloud.net/cg-project-confession/statistics-bg-desktop");
    height: 365px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .statistics-counter-bg {
   width: 1530px;
    position: relative;
    z-index: 100;
   height: 280px;
   background-image: url("https://storage.yandexcloud.net/cg-project-confession/counter-desktop");
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
   justify-content: space-around;
    -webkit-align-items: center;
   align-items: center;
  }
  .statistics-counter{
   width: 1220px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
   align-items: center;
  }

  @media (max-width: 1919px) {
    .statistics-section {
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/statistics-bg-tablet");
      position: relative;
      z-index: 20;
      max-width: 1440px;
      height: 360px;
    }
    .statistics-counter-bg {
      height: 320px;
      width: 1133px;
      background-repeat: no-repeat;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/counter-tablet");
    }
    .statistics-counter {
      padding-top: 47px;
      width: 1030px;
    }
    }
  @media (max-width: 1132px) {
    .statistics-section {
      max-width: 960px;
      height: 458px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/statistics-tablet-vertical");
    }
    .statistics-counter-bg {
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/counter-tablet-vertical");
      height: 410px;
      width: 620px;
      transform: translate(0);
    }
    .statistics-counter {
      padding-top: 0;
      width: 500px;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;

      & div:nth-of-type(n){
        margin-right: 40px;
      }
      & div:nth-of-type(3n){
        margin-right: 0;
      }

      & div:nth-of-type(1),
      div:nth-of-type(2),
      div:nth-of-type(3){
        margin-bottom: 40px;
      }
    }
  }
  @media (max-width: 743px) {
    .statistics-section {
      max-width: 430px;
      height: 550px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/statistics-phone");
    }
    .counter-text{
      color: $white !important;
    }
    .statistics-counter-bg {
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/counter-phone");
      height: 550px;
      width: 320px;
      transform: translateY(-11px);
    }
    .statistics-counter {
      width: 290px;
      height: 425px;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;

      & div:nth-of-type(n){
        margin: 0px;
      }

    }
  }

</style>
