<template>
  <div class="slide-block" :style="cssContainerProps">
    <div class="title-block">
      <TypographyPrimitive is="h2" :font="fonts.balkaraCondensed" :color="colors.green" :mode="typographyMode.title_mobileSlide"><slot name="header" /></TypographyPrimitive>
      <TypographyPrimitive is="p" color="#FFEECC" :mode="typographyMode.mainText_mobileSlide" ><slot name="teaser" /></TypographyPrimitive>
      <TypographyPrimitive is="h2" :font="fonts.balkaraCondensed" :mode="typographyMode.title_mobileSlideTime"><slot name="time" /></TypographyPrimitive>
    </div>
    <div class="slide-border" :class="slideBg" :style="cssSlideProps">
      <div class="slide" :class="{'slide--py-small':small}">
      <div v-for="paper of papersContent" :key="paper.text" class="speaker-block">
        <img :src="require(`@/assets/img/map-components/slides/${paper.photo}`)" alt="speaker" class="speaker" />
        <div class="paper-patch">
          <TypographyPrimitive is="p" :color="colors.black"> {{paper.text}} </TypographyPrimitive>
          <TypographyPrimitive is="p" :color="colors.black" :font="fonts.jostVariable"> {{paper.description}} </TypographyPrimitive>
        </div>
      </div>
      <TypographyPrimitive is="p" class="description" :font="fonts.jostVariable" :mode="typographyMode.mainText_mobileSlide"><slot name="description"/> </TypographyPrimitive>
      </div>
    </div>
  </div>

</template>

<script>
import TypographyPrimitive from '@/components/Primitives/Typography'
import { typographyMode } from '@/themes/primitivesModes'
import fonts from '@/themes/fonts'
import colors from '@/themes/colors'
export default {
  name: 'MobileSLide',
  components: { TypographyPrimitive },
  data: function () {
    return {
      typographyMode, fonts, colors
    }
  },
  props: {
    height: String,
    slideHeight: String,
    slideBg: String,
    papersContent: Array,
    small: Boolean
  },
  computed: {
    cssContainerProps () {
      return {
        '--height': this.height
      }
    },
    cssSlideProps () {
      return {
        '--slide-height': this.slideHeight,
        '--slide-bg': this.slideBg
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-block {
  height: var(--height);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-flow: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.title-block{
  text-align: start;
  width: 316px;
  padding-right: 2px;
  margin: auto;
}

.slide-border{
  height: var(--slide-height);
  width: 320px;
  margin: auto;
}

.slide{
  width: 100%;
  height: 100%;
  padding: 58px 20px;

  &--py-small{
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

.speaker-block{
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  width: 280px;
  margin-bottom: 10px;
}

.photo-placeholder{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #D9D9D9;
}

.description{
  margin-top: 20px;
  width: 280px;
  text-align: start;
  line-height: 110%;
}

.slide-460{
  background-image: url("@/assets/img/map-components/mobile-slide(460).png");
}

.slide-390{
  background-image: url("@/assets/img/map-components/mobile-slide(390).png");
}

.slide-500{
  background-image: url("@/assets/img/map-components/mobile-slide(500).png");
}

.slide-650{
  background-image: url("@/assets/img/map-components/mobile-slide(650).png");
}

.paper-patch{
  background-image: url("@/assets/img/map-components/paper.png");
  width: 220px;
  height: 80px;
  background-size: cover;
  padding-right: 8px;

  & p:first-of-type{
    margin-top: 15px;
    margin-left: 17px;
    text-align: start;
    font-size: $typographySmall;
    margin-bottom: 4px;
  }
  & p:last-of-type{
    margin-left: 17px;
    text-align: start;
    font-size: $typographyExtraSmall;
    line-height: 110%;
  }

}

.speaker{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

</style>
