const images = {
  desktop: [
    'https://storage.yandexcloud.net/cg-project-confession/tree-gif',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/serpent/serpent-desktop.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/game.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/book.png',
    'https://storage.yandexcloud.net/cg-project-confession/interaction-bg.png',
    'https://storage.yandexcloud.net/cg-project-confession/game/video-background-desktop.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/welcome-popup-desktop.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-1.png'
  ],

  'tablet-h': [
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/serpent/serpent-tablet-lg.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/game.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/book.png',
    'https://storage.yandexcloud.net/cg-project-confession/interaction-bg.png',
    'https://storage.yandexcloud.net/cg-project-confession/game/video-background-tablet-h.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/welcome-popup-desktop.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-1.png'
  ],
  'tablet-v': [
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/serpent/serpent-tablet-sm.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/game.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/book.png',
    'https://storage.yandexcloud.net/cg-project-confession/interaction-bg.png',
    'https://storage.yandexcloud.net/cg-project-confession/game/video-background-tablet-v.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/welcome-popup-tablet-v.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/serpent/serpent-tablet-lg.png'
  ],
  'mobile-xl': [
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/game.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/book.png',
    'https://storage.yandexcloud.net/cg-project-confession/interaction-bg.png',
    'https://storage.yandexcloud.net/cg-project-confession/game/video-background-mobile.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/welcome-popup-mobile.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-1-mobile.png'
  ],
  'mobile-xs': [
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-left-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-1.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-2.png',
    'https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-3.png',
    'https://storage.yandexcloud.net/cg-project-confession/serpent/serpent-mobile.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/game.png',
    'https://storage.yandexcloud.net/cg-project-confession/program/book.png',
    'https://storage.yandexcloud.net/cg-project-confession/interaction-bg.png',
    'https://storage.yandexcloud.net/cg-project-confession/game/video-background-mobile.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/welcome-popup-mobile.png',
    'https://storage.yandexcloud.net/cg-project-confession/modal/modal-description-1-mobile.png'
  ]
}

export default images
