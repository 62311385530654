<template>
  <div class="map-mobile">
    <div class="points-container">
      <MapPoint
        :position="{top: pointsData[0].top, left: pointsData[0].left}"
        :time="{content: '11:00', mode: pointsData[0].mode }"
        :description="{content: 'Присказка Исповеди', mode: pointsData[0].mode }"
      />
      <MapPoint
        :position="{top: pointsData[1].top, left: pointsData[1].left}"
        :time="{content: '', mode: pointsData[1].mode }"
        :description="{content: '', mode: pointsData[1].mode }"
      />
      <MapPoint
        :position="{top: pointsData[2].top, left: pointsData[2].left}"
        :time="{content: '', mode: pointsData[2].mode }"
        :description="{content: '', mode: pointsData[2].mode }"
      />
      <MapPoint
        :position="{top: pointsData[3].top, left: pointsData[3].left}"
        :time="{content: '', mode: pointsData[3].mode }"
        :description="{content: '', mode: pointsData[3].mode }"
      />
      <MapPoint
        :position="{top: pointsData[4].top, left: pointsData[4].left}"
        :time="{content: '', mode: pointsData[4].mode }"
        :description="{content: '', mode: pointsData[4].mode }"
      />
      <MapPoint
        :position="{top: pointsData[5].top, left: pointsData[5].left}"
        :time="{content: '', mode: pointsData[5].mode }"
        :description="{content: '', mode: pointsData[5].mode }"
      />
      <MapPoint
        :position="{top: pointsData[6].top, left: pointsData[6].left}"
        :time="{content: '14:40', mode: pointsData[6].mode }"
        :description="{content: 'Тайное мероприятие', mode: pointsData[6].mode }"
      />
      <MapPoint
        :position="{top: pointsData[7].top, left: pointsData[7].left}"
        :time="{content: '15:30', mode: pointsData[7].mode }"
        :description="{content: 'Концесловие Исповеди', mode: pointsData[7].mode }"
      />
    </div>
    <div class="slide-container">
      <MobileSLide
        height="500px"
        slide-height="500px"
        slide-bg="slide-500"
        :papers-content="papersData[0].data"
        small
      >
        <template v-slot:header>Исследование</template>
        <template v-slot:teaser>Препятствие первое: Баба Яга против</template>
        <template v-slot:time>С 11:20 до 12:00</template>
        <template v-slot:description>Предпроектное исследование может уберечь проект от провала. В каких ситуациях нужно исследование и как использовать его в работе — расскажем на конференции.</template>
      </MobileSLide>
      <MobileSLide height="535px" slide-height="390px" slide-bg="slide-390" :papers-content="papersData[1].data">
        <template v-slot:header>Проектирование</template>
        <template v-slot:teaser>Препятствие второе: Кощей над концептом чахнет…</template>
        <template v-slot:time>С 12:00 до 12:40</template>
        <template v-slot:description>Разберём, как лучше прорабатывать конечный образ продукта, что поможет донести его до команды и как прописать сильный экшн-план. </template>
      </MobileSLide>
      <MobileSLide height="605px" slide-height="460px" slide-bg="slide-460" :papers-content="papersData[2].data">
        <template v-slot:header>Дизайн</template>
        <template v-slot:teaser>Препятствие третье: они могли создать шедевр из чего угодно</template>
        <template v-slot:time>С 12:40 до 13:20</template>
        <template v-slot:description>Сильный дизайн со всех сторон: как дизайнерам общаться с командой, проектировать пользовательский опыт и создавать дизайн-системы. </template>
      </MobileSLide>
      <MobileSLide height="821px" slide-height="650px" slide-bg="slide-650" :papers-content="papersData[3].data">
        <template v-slot:header>Разработка</template>
        <template v-slot:teaser>Препятствие четвёртое: выживи в проектном огне, как Жар-птица</template>
        <template v-slot:time>С 13:20 до 14:00</template>
        <template v-slot:description>Можно ли спланировать спринты так, чтобы не делать всё в последнюю ночь? — Да. Мы расскажем, как оценить ёмкость спринтов и учесть даже форс-мажоры. </template>
      </MobileSLide>
      <MobileSLide height="535px" slide-height="390px" slide-bg="slide-390" :papers-content="papersData[4].data">
        <template v-slot:header>Тестирование</template>
        <template v-slot:teaser>Препятствие пятое: в тихом омуте тестировщики водятся…</template>
        <template v-slot:time>С 14:00 до 14:40</template>
        <template v-slot:description>Тестирование поможет не упасть в грязь лицом в день релиза. Какую обратную связь собрать от аудитории и как её внедрить, если скоро релиз — поделимся на Исповеди.</template>
      </MobileSLide>
    </div>
  </div>
</template>

<script>
import { useBreakpoints } from '@/utils/useBreakpoints'
import MapPoint from '@/components/sections/Map/MapPoint'
import pointsDataMap from '@/themes/pointsDataMap'
import MobileSLide from '@/components/sections/Map/MobileSlide'

export default {
  name: 'MapMobileSchedule',
  components: {
    MobileSLide,
    MapPoint
  },
  data: function () {
    return {
      pointsDataMap,
      papersData: [
        {
          data: [
            { text: 'Светлана Маркина', description: 'Директор департамента ИТ анализа', photo: 'research-first-speaker.jpg' },
            { text: 'Алексей Грибанов', description: 'Директор департамента архитектуры решений в Ростелекоме', photo: 'research-second-speaker.jpg' },
            {
              text: 'Татьяна Минькова',
              description:
                'CX/UX-исследователь в Альфа-банке, преподаватель Alfa Academy',
              photo: 'research-third-speaker2.png'
            },
            {
              text: 'Мария Гостяева',
              description:
                'CX/UX-исследователь в Альфа-банке, преподаватель Alfa Academy',
              photo:
                'research-forth-speaker.png'
            }
          ]
        },
        {
          data: [
            { text: 'Дарий Халитов', description: 'Вице-президент по развитию информационных систем ПАО Ростелеком', photo: 'engineering-first-speaker.jpg' },
            {
              text: 'Дамир Шафигулин',
              description:
                'Руководитель цифровых продуктов в Альфа-банке',
              photo:
                'engineering-second-speaker.png'
            }
          ]
        },
        {
          data: [
            { text: 'Станислав Жибоедов', description: 'Веб-дизайнер в Ростелекоме', photo: 'design-first-speaker.jpg' },
            { text: 'Никита Денисенко', description: 'Дизайн-лид и продакт-оунер в Спектре, лауреат премии Red Dot', photo: 'design-second-speaker.jpg' },
            { text: 'Денис Пушкарь', description: 'Продакт-оунер в Ростелекоме. Сделал дизайн-системы СберЗвука и Ростелекома.', photo: 'design-third-speaker.jpg' }
          ]
        },
        {
          data: [
            { text: 'Андрей Пахомов', description: 'Руководитель группы по разработке цифровых продуктов в Ростелекоме', photo: 'development-first-speaker.jpg' },
            { text: 'Юрий Плотников', description: 'Тимлид бэкенд-разработки, системный архитектор в Ростелекоме', photo: 'development-second-speaker.jpg' },
            {
              text: 'Ольга Головачева',
              description:
                'Директор ЦК по импортозамещению в Ростелекоме',
              photo:
                'development-third-speaker.png'
            },
            { text: 'Валерия Богданова', description: 'Продакт-дизайнер в Center-Game', photo: 'development-forth-speaker.jpg' },
            { text: 'Артур Гобеев', description: 'Сооснователь Center-Game. Руководитель EdTech-проектов на 10 млн пользователей', photo: 'development-fifth-speaker.jpg' }
          ]
        },
        {
          data: [
            { text: 'Анастасия Радужная', description: 'И. о. директора департамента тестирования и обеспечения качества', photo: 'testing-first-speaker.jpg' },
            { text: 'Олег Королёв', description: 'Руководитель отдела тестирования ЦК по внедрению и развитию CMS-решений в Ростелекоме', photo: 'testing-second-speaker.jpg' }
          ]
        }
      ]
    }
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  },
  computed: {
    pointsData () {
      return this.pointsDataMap[this.type]
    }
  }
}
</script>

<style lang="scss" scoped>
 .map-mobile{
   max-width: 430px;
   height: 4000px;
   position: relative;
 }

 .points-container{
   height: 100%;
   width: 430px;
   position: relative;
 }

 .slide-container{
   position: absolute;
   top: 148px;
   width: 100%;

   & div:first-of-type,
   div:nth-of-type(3),
   div:nth-of-type(4){
     margin-bottom: 106px;
   }

   & div:nth-of-type(2){
     margin-bottom: 76px;
   }
 }

 @media (max-width: 429px) {
   .points-container{
     max-width: 320px;
     margin: auto;
   }
 }
</style>
