<template>
  <typography is="h2" class="title" id="privacy-title">
    {{ title }}
  </typography>
  <div class="paragraphs-container">
    <typography
      is="p"
      v-for="(paragraph, index) in paragraphs"
      :key="index"
      :font="fonts.jostVariable"
      class="content"
    >
      {{ paragraph }}
    </typography>
  </div>
</template>

<script>
import Typography from '@/components/Primitives/Typography'
import fonts from '@/themes/fonts'

export default {
  name: 'PrivacySection',
  components: {
    Typography
  },
  data () {
    return {
      fonts
    }
  },
  props: {
    title: String,
    paragraphs: Array
  }
}
</script>

<style lang="scss" scoped>
  .paragraphs-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin-bottom: 40px;

    & p:nth-of-type(n){
      margin-bottom: 35px;
    }

    & p:last-of-type{
      margin-bottom: 0;
    }

  }

  .title, .content {
    text-align: left;
  }

  .content {
    width: auto;
  }

  .title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  @media (max-width: 1132px) {
    .title, .content {
      font-size: 18px;
    }
  }
  @media (max-width: 743px) {
    .title, .content {
      font-size: 16px;
    }
  }
</style>
