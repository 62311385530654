<template>
  <img
    v-if="!isMainRoutes"
    class="pageBackground"
    src="https://storage.yandexcloud.net/cg-project-confession/page-background"
  />

  <div v-else-if="loaded" class="pageProgramBackground">
    <ThreesLeft :scrollPercent="scrollPercent" />
    <ThreesRight :scrollPercent="scrollPercent" />
    <img class="bg-helper"
         src="https://storage.yandexcloud.net/cg-project-confession/tree/tree-bg.jpg"
         :style="bgHelperStyle"
         alt="bg"/>
  </div>
  <div v-if="loaded">
    <transition name="modal">
      <WelcomePopUp v-if="!welcomeModalShown" @closeModal="welcomeModalShown = true"/>
    </transition>
    <Header />
    <div class="legend" id="leg"></div>
    <span class="page-top" id="page-top"></span>
    <router-view @scrollPercent="setScrollPercent" />
    <scroll-to-top-button :isVisible="scrollPercent > 5" />
    <Footer v-if="$route.name !== 'privacy'" />
  </div>
  <img
    v-else
    class="loader"
    src="https://storage.yandexcloud.net/cg-project-confession/loader"
  />
</template>

<script>
import Header from '@/components/sections/Header'
import Footer from '@/components/sections/Footer.vue'
import { useBreakpoints } from '@/utils/useBreakpoints'
import images from '@/configs/preloadImages'
import imagesAbout from '@/configs/preloadImagesAboutSectoin'
import WelcomePopUp from '@/components/Modals/WelcomePopUp'
import ScrollToTopButton from './components/Primitives/ScrollToTopButton.vue'
import ThreesLeft from '@/components/sections/Threes/ThreesLeft'
import ThreesRight from '@/components/sections/Threes/ThreesRight'

export default {
  components: {
    Header,
    Footer,
    WelcomePopUp,
    ScrollToTopButton,
    ThreesLeft,
    ThreesRight
  },
  data () {
    return {
      loaded: false,
      scrollPercent: 0,
      welcomeModalShown: false,
      mainImagesPreloaded: false,
      aboutImagesPreloaded: false
    }
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  },

  computed: {
    isMainRoutes () {
      const mainRoutes = ['program', 'game-dii', 'tale-dii', 'game-end', 'tale-end']
      return mainRoutes.includes(this.$route.name)
    },
    bgHelperStyle () {
      return `opacity: ${this.scrollPercent * 2}%`
    }
  },

  methods: {
    startPreloading () {
      if (this.isMainRoutes && this.mainImagesPreloaded) {
        return
      }
      if (!this.isMainRoutes && this.aboutImagesPreloaded) {
        return
      }
      this.loaded = false
      const load = (url) => {
        return new Promise((resolve) => {
          const image = new Image()
          image.src = url
          image.onload = () => resolve(image)
        })
      }
      const getImgs = (sources) => {
        const promises = sources.map((source) => {
          return load(source)
        })
        return Promise.all(promises)
      }

      const img = this.isMainRoutes ? images : imagesAbout
      getImgs(img[this.type]).then(() => {
        if (this.isMainRoutes) {
          this.mainImagesPreloaded = true
        } else {
          this.aboutImagesPreloaded = true
        }
        this.loaded = true
      })
    },
    setScrollPercent (percent) {
      this.scrollPercent = percent / 2
    },
    threeStyle (side) {
      const firstLayerSizeScale = 0.001
      const secondLayerSizeScale = 0.003
      const thirdLayerSizeScale = 0.005

      const firstLayerPositionScale = 0.4
      const secondLayerPositionScale = 0.25
      const thirdLayerPositionScale = 0.2

      const firstLayerblurScale = 0.08
      const secondLayerblurScale = 0.05
      const position = {
        'left-1': {
          size: 1 + this.scrollPercent * thirdLayerSizeScale,
          position: -1 - this.scrollPercent * thirdLayerPositionScale,
          blur: this.scrollPercent * firstLayerblurScale
        },
        'left-2': {
          size: 1 + this.scrollPercent * secondLayerSizeScale,
          position: -1 - this.scrollPercent * secondLayerPositionScale,
          blur: this.scrollPercent * secondLayerblurScale
        },
        'left-3': {
          size: 1 + this.scrollPercent * firstLayerSizeScale,
          position: -1 - this.scrollPercent * firstLayerPositionScale
        },
        'right-1': {
          size: 1 + this.scrollPercent * thirdLayerSizeScale,
          position: 0 - this.scrollPercent * thirdLayerPositionScale,
          blur: this.scrollPercent * firstLayerblurScale
        },
        'right-2': {
          size: 1 + this.scrollPercent * secondLayerSizeScale,
          position: 0 - this.scrollPercent * secondLayerPositionScale,
          blur: this.scrollPercent * secondLayerblurScale
        },
        'right-3': {
          size: 1 + this.scrollPercent * firstLayerSizeScale,
          position: 0 - this.scrollPercent * firstLayerPositionScale
        }
      }
      return `${side.includes('left') ? 'left' : 'right'}: ${position[side].position}%;
     filter: blur(${position[side]?.blur || 0}px);
      `
    }
  },

  watch: {
    type () {
      if (this.isMainRoutes) {
        return
      }
      this.startPreloading()
    },
    welcomeModalShown: {
      handler (modalIsShown, _) {
        if (!modalIsShown) {
          document.body.classList.add('lock-position')
        } else {
          document.body.classList.remove('lock-position')
        }
      },
      immediate: true
    },
    '$route.name': {
      handler () {
        this.startPreloading()
      }
    }
  },

  created () {
    this.welcomeModalShown = !!localStorage.getItem('welcomeModalShown')
    if (!this.welcomeModalShown) {
      localStorage.setItem('welcomeModalShown', true)
    }
  }
}
</script>

<style lang="scss">
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.loader {
  display: block;
  position: absolute;
  z-index: 2;
  width: 100px;
  height: 100px;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  animation: rotation 20s linear infinite;
}

@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(8000deg);
  }
}

* {
  padding: 0;
  margin: 0;
}
.root,
body {
  background-color: #101010;
}
body.lock-position {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
}
.page-top {
  position: absolute;
  top: 0;
}

.custom-cursor {
  cursor: url("../public/hand-cursor.png") 15 0, auto;
}

.legend {
  position: absolute;
  top: 800px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.pageBackground {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100vh;
  inset: 0;
  object-position: center;
  object-fit: cover;
  display: block;
}

.pageProgramBackground {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100vh;
  inset: 0;
  background-image:
    url("https://storage.yandexcloud.net/cg-project-confession/tree/program-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  pointer-events: none;

  img {
    height: 100vh;
    position: absolute;
    transition: all 150ms ease-out;
    will-change: all;

    @include tablet-lg{
      display: none;
    }
    @include tablet-sm{
      display: none;
    }
    @include mobile{
      display: none;
    }

    @keyframes rotate-right {
      50% {
        transform: rotate(0.2deg)
      }
    }
    @keyframes rotate-left {
      50% {
        transform: rotate(-0.2deg)
      }
    }
  }
  .tree-right-1,
  .tree-right-2,
  .tree-right-3 {
    right: 0;
    bottom: 0;
    top: 0;
    transform-origin: bottom right;
    z-index: 1;
  }

  .tree-right-1{
    animation: rotate-left infinite ease-in-out 4s 1s;
  }
  .tree-right-2{
    animation: rotate-left infinite ease-in-out 4s 1.5s;
  }
  .tree-right-3{
    animation: rotate-left infinite ease-in-out 4s 2s;
  }

  .tree-left-1,
  .tree-left-2,
  .tree-left-3 {
    left: 0;
    bottom: 0;
    top: 0;
    transform-origin: bottom left;
    z-index: 1;
  }
  .tree-left-1{ animation: rotate-right infinite ease-in-out 4s 0.5s;}
  .tree-left-2{ animation: rotate-right infinite ease-in-out 4s 1s;}
  .tree-left-3{ animation: rotate-right infinite ease-in-out 4s 1.5s;}

  .bg-helper{
    position: absolute;
    inset: 0;
    opacity: 0;
    z-index: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;

    @include tablet-lg{
      display: none;
    }
    @include tablet-sm{
      display: none;
    }
    @include mobile{
      display: none;
    }
  }
}

@media (max-width: 1919px) {
  .legend {
    position: absolute;
    top: 900px;
  }
}

@media (max-width: 1133px) {
  .legend {
    position: absolute;
    top: 600px;
  }
}

@media (max-width: 744px) {
  .legend {
    position: absolute;
    top: 600px;
  }
}

@media (max-width: 375px) {
  .legend {
    top: 600px;
  }
}
</style>
