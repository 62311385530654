<template>
  <div class="slide" >
    <transition name="slide-fade">
      <img :src="`${backgrounds[speakersIndex]}`" rel="prefetch" :key="backgrounds[speakersIndex]" alt="background" class="background" />
    </transition>
  <div class="text-wrapper">
      <Typography is="h2" :mode="typographyMode.title_slide" :font="fonts.balkaraCondensed"><slot name="title"/></Typography>
      <Typography is="p" :mode="typographyMode.mainText_slide" class="teaser" ><slot name="teaser" /></Typography>
      <Typography is="p" :mode="typographyMode.mainText_slide"><slot name="description" /></Typography>
      <Typography is="h2" :mode="typographyMode.title_slideTime" :font="fonts.balkaraCondensed"><slot name="time" /></Typography>
    </div>
    <transition-group tag="div" name="speaker-next" class="paper-patches-container" v-if="paperPatches.length">
      <div v-for="patch in paperPatches[speakersIndex]" :key="patch.name" class="paper-patch" :style="{bottom: patch.positions[type].bottom, left: patch.positions[type].left}" :class="patch.className" >
        <Typography is="p" :color="colors.black" :font="fonts.jostMedium" :mode="typographyMode.addText_slideSpeakerName">{{patch.name}}</Typography>
        <Typography is="p" :color="colors.black" :font="fonts.jostVariable" :mode="typographyMode.addText_footer_slideSpeakerInfo">{{patch.description}}</Typography>
      </div>
    </transition-group>
    <div class="button-wrapper" v-if="paperPatches.length > 1">
     <ButtonPrimitive
       v-for="(patch, index) in paperPatches.length"
       :key="patch.name"
       @click="() => changeSpeakerFromButton(index)"
       mode="circle"
       :class="index === speakersIndex && 'active-button'"
     />
    </div>
  </div>
</template>

<script>
import Typography from '@/components/Primitives/Typography'
import { typographyMode } from '@/themes/primitivesModes'
import fonts from '@/themes/fonts'
import colors from '@/themes/colors'
import { useBreakpoints } from '@/utils/useBreakpoints'
import ButtonPrimitive from '@/components/Primitives/Button'
export default {
  name: 'SliderSlide',
  components: {
    ButtonPrimitive,
    Typography
  },
  data: function () {
    return {
      typographyMode,
      fonts,
      colors,
      speakersIndex: 0,
      timeoutIndex: ''
    }
  },
  props: {
    paperPatches: Array,
    backgrounds: Array
  },
  methods: {
    changeSpeaker () {
      const len = this.paperPatches.length
      if (len !== 0) {
        this.speakersIndex = (this.speakersIndex + 1 % len + len) % len
      }
    },
    changeSpeakerFromButton (num) {
      clearTimeout(this.timeoutIndex)
      this.speakersIndex = num
    }
  },
  mounted () {
    this.timeoutIndex = setTimeout(this.changeSpeaker, 5000)
  },
  unmounted () {
    clearTimeout(this.timeoutIndex)
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  },
  watch: {
    speakersIndex () {
      this.timeoutIndex = setTimeout(this.changeSpeaker, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .slide{
    width: 1258px;
    height: 495px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .background{
    position: absolute;
    z-index: 1;
    height: 100%;
  }

  .slide-fade-enter-active {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    transition-delay: 0.4s;
  }

  .slide-fade-enter-active img {
    transition: opacity 0.5s ease-in-out;
    transition-delay: 0.4s;
  }

  .slide-fade-leave-active {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
  }

  .slide-fade-leave-active img {
    transition: opacity 0.4s ease-in-out;
  }

  .slide-fade-enter-to{
    opacity: 1;
  }
  .slide-fade-leave-to {
    opacity: 0;
  }

  .slide-fade-enter img, .slide-fade-leave-to img {
    filter: blur(10px);
    transform: scale(1.2) rotate(3deg);
  }

  .speaker-next-enter-active,
  .speaker-next-leave-active{
     transition: opacity 0.5s ease-in-out;
  }

  .speaker-next-enter-active{
    transition-delay: 0.4s;
  }

  .speaker-next-enter-active{
    opacity: 0;
  }

  .speaker-next-enter-to{
    opacity: 1;
  }

  .speaker-next-leave-active{
    opacity: 1;
  }

  .speaker-next-leave-to{
    opacity: 0;
  }

  .paper-patches-container{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .paper-patch{
    background-image: url("@/assets/img/map-components/paper.png");
    background-repeat: no-repeat;
    text-align: start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    flex-flow: column;
    position: absolute;

    & p {
      width: 240px;
      margin-left: 30px;
    }
  }

  .text-wrapper{
    width: 480px;
    text-align: start;
    margin-top: 40px;
    margin-right: 80px;
    z-index: 10;

    & h2:first-of-type {
      margin-bottom: 20px;
    }

    & p:nth-of-type(1){
      margin-bottom: 10px;
    }

    & p:nth-of-type(2){
      margin-bottom: 40px;
    }

  }

  .button-wrapper{
     z-index: 20;
    position: absolute;
    bottom: 5px;
    left: 300px;
  }

  .active-button{
    background-color: $green;
  }

  .patch-default,
  .patch-large,
  .patch-small,
  .patch-large-plus,
  .patch-large-alt{
    width: 281px;
    height: 112px;
    background-size: 281px 112px;
  }

  .patch-extra-small,
  .patch-small-plus{
    width: 281px;
    height: 90px;
    background-size: 281px 90px;
  }

  .patch-big{
    width: 281px;
    height: 120px;
    background-size: 281px 120px;

    & p:last-of-type{
      width: 230px;
    }
  }

  @media (max-width: 1919px) {
    .slide{
      max-width: 1440px;
      width: 1100px;
      height: 450px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .background{
      width: 100%;
    }

    .highlight{
      color: $white;
    }

    .teaser{
      color: #FFEECC;
    }

    .text-wrapper{
      width: 500px;
      margin-right: 50px;
      margin-top: 100px;
    }

    .patch-default,
    .patch-large,
    .patch-small-plus{
      width: 250px;
      height: 110px;
      background-size: 250px 110px;
    }

    .patch-default{
      & p:last-of-type{
        width: 220px;
      }
    }

    .patch-large{
      p:first-of-type{
       margin-left: 25px;
      }

      & p:last-of-type{
        width: 225px;
        margin-left: 25px;
      }
    }

    .patch-small,
    .patch-extra-small{
      width: 250px;
      height: 90px;
      background-size: 250px 90px;
    }

    .patch-big{
      width: 250px;
      background-size: 250px 120px;

      & p:last-of-type{
        width: 215px;
      }
    }

    .patch-large-plus{
      width: 250px;
      height: 120px;
      background-size: 250px 120px;

      & p {
        margin-left: 25px;
      }

      & p:last-of-type{
        width: 219px;
      }
    }

    .patch-large-alt{
      width: 258px;
      height: 110px;
      background-size: 258px 110px;

      & p{
        margin-left: 25px;
      }
    }

  }

  @media (max-width: 1132px) {
   .slide{
     background-image: none;
     height: 870px;
     width: 714px;
     -webkit-justify-content: flex-start;
     justify-content: flex-start;
   }

    .text-wrapper{
      margin-top: 60px;
      margin-left: 50px;
      width: 700px;

      & p:first-of-type{
        margin-left: 160px;
        margin-bottom: 450px;
      }

      & p:last-of-type{
        margin-left: 160px;
        margin-bottom: 30px;
      }

      & h2:last-of-type{
        margin-left: 160px;
      }
    }

    .teaser{
      height: 50px;
      color: #FFEECC;
    }

    .patch-default{
      width: 250px;
      height: 100px;
      background-size: 250px 100px;
    }

    .patch-large-alt{
      width: 270px;
      height: 110px;
      background-size: 270px 110px;
    }

    .button-wrapper{
      bottom: 260px;
      left: 340px;
    }

  }
</style>
