export default {
  background: {
    color: {
      value: 'transparent'
    },

    size: {
      value: '100%'
    }
  },
  fullScreen: {
    enable: false
  },
  fpsLimit: 60,
  interactivity: {
    events: {
      onClick: {
        enable: false
      },
      onHover: {
        enable: false
      },
      resize: false
    }
  },
  particles: {
    color: {
      value: '#FFF'
    },
    links: {
      color: ['#FFF', '#EDB300'],
      distance: 100,
      enable: false,
      opacity: 0.4,
      width: 1
    },
    collisions: {
      enable: false
    },
    move: {
      direction: 'none',
      enable: true,
      outMode: 'out',
      random: true,
      speed: {
        min: 2,
        max: 5
      },
      straight: false
    },
    number: {
      density: {
        enable: true,
        area: 2000
      },
      value: 15
    },
    opacity: {
      value: 1
    },
    shape: {
      type: 'image',
      options: {
        image: [{
          src: 'https://storage.yandexcloud.net/cg-project-confession/fireflies/moth-01.svg',
          width: 100,
          height: 100,
          replaceColor: false
        },
        {
          src: 'https://storage.yandexcloud.net/cg-project-confession/fireflies/moth-02.svg',
          width: 100,
          height: 100,
          replaceColor: false
        },
        {
          src: 'https://storage.yandexcloud.net/cg-project-confession/fireflies/moth-03.svg',
          width: 100,
          height: 100,
          replaceColor: false
        },
        {
          src: 'https://storage.yandexcloud.net/cg-project-confession/fireflies/moth-04.svg',
          width: 100,
          height: 100,
          replaceColor: false
        },
        {
          src: 'https://storage.yandexcloud.net/cg-project-confession/fireflies/moth-05.svg',
          width: 100,
          height: 100,
          replaceColor: false
        },
        {
          src: 'https://storage.yandexcloud.net/cg-project-confession/fireflies/moth-06.svg',
          width: 100,
          height: 100,
          replaceColor: false
        },
        {
          src: 'https://storage.yandexcloud.net/cg-project-confession/fireflies/moth-07.svg',
          width: 100,
          height: 100,
          replaceColor: false
        },
        {
          src: 'https://storage.yandexcloud.net/cg-project-confession/fireflies/moth-08.svg',
          width: 100,
          height: 100,
          replaceColor: false
        }
        ]
      }
    },
    stroke: {
      width: 0,
      color: '#000'
    },
    polygon: {
      nb_sides: 6
    },
    size: {
      random: false,
      value: 20
    }
  },
  detectRetina: true
}
