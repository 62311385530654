<template>
  <component :is="is" :style="cssProps" class="typography" :class="mode"><slot /></component>
</template>

<script>
import { typographyMode } from '@/themes/primitivesModes'
import fonts from '@/themes/fonts'

export default {
  name: 'TypographyPrimitive',
  props: {
    is: String,
    mode: { type: String, default: typographyMode.general },
    color: { type: String, default: '#FAFFF6' },
    additionalColor: { type: String },
    font: { type: String, default: fonts.jostMedium }
  },
  computed: {
    cssProps () {
      return {
        '--font-color': this.color,
        '--font-family': this.font,
        '--font-additional-color': this.additionalColor ? this.additionalColor : this.color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .typography{
    font-weight: 400;
    font-family: var(--font-family);
    color: var(--font-color);
  }
  .title_serpent {
    font-size: $typographyBig;
  }
  .title_reviews {
    font-size: $typographyBigMedium;
  }
  .title_regThanks {
    font-size: $typographyBig;
  }
  .title_map_participate{
    font-size: $typographyBigMedium;
    text-transform: uppercase;
  }

  .title_slide{
    font-size: $typographyBigMedium;
  }
  .title_slide-time {
    font-size: $typographyLargeMinus;
  }

  .add-text_slide-speaker-name {
    font-size: $typographyMediumMinus;
  }

  .add-text_footer_slide-speaker-info{
    font-size: $typographySmall;
  }

  .title_registration {
    font-size: $typographyBigMedium;
  }

  .title_participate-cards {
    font-size: $typographyBig;
  }

  .title_map_participate {
    font-size: 50px;
  }
  .title_counter-data {
    font-size: $typographyBig;
  }
  .general {
    font-size: $typographyMedium;
  }

  .main-text_map {
    font-size: $typographyMedium;
  }

  .main-text_slide{
    font-size: $typographyMedium;
  }

  .main-text_participate-cards {
    font-size: $typographyMedium;
  }

  .main-text_counter-data{
    font-size: $typographyMedium;
  }

  .title_mobile-slide{
    font-size: $typographyLargeSmall;
  }

  .title_mobile-slide-time{
    font-size: $typographyMediumSmall;
  }

  .main-text_mobile-slide{
    font-size: $typographySmallPlus;
  }

  .add-text_registration-validation{
    font-size: $typographyMediumMinus;
  }
  .program_title{
    font-size: $typographyLargePlus;
    @include tablet-sm{
      font-size: $typographyLargeMinus;
    }
    @include mobile{
      font-size: $typographyLargeMinus;
    }
  }

  .slider-description{
    font-size: $typographyMediumMinus;
    line-height: 130%;
    @include mobile{
      font-size: $typographySmallPlus;
    }
  }

  @media (max-width: 1919px){
    .title_serpent {
      font-size: $typographyLargePlus;
    }
    .title_regThanks {
      font-size: $typographyLargePlus;
    }
    .title_reviews {
      font-size: $typographyLarge;
    }
    .title_participate-cards {
      font-size: $typographyLargePlus;
    }
    .title_registration {
      font-size: $typographyLarge;
    }
    .main-text_participate-cards {
      font-size: $typographyMediumMinus;
    }
    .main-text_slide {
      font-size: $typographyMediumMinus;
    }
    .title_map_participate {
      font-size: 40px;
    }
    .title_slide{
      font-size: $typographyLargeMinus;
    }
  }

  @media (max-width: 1133px) {
    .title_regThanks {
      font-size: $typographyLargePlus;
    }
    .title_serpent {
      font-size: $typographyLargeMinus;
    }
    .title_counter-data {
      font-size: $typographyLargePlus;
    }
    .title_registration {
      font-size: $typographyMediumBig;
    }
    .general {
      font-size: $typographyMediumMinus;
    }
    .main-text_map {
      font-size: $typographyMediumMinus;
    }
    .main-text_participate-cards {
      font-size: $typographySmallPlus;
    }
    .main-text_counter-data{
      font-size: $typographyMediumMinus;
    }
  }

  @media (max-width: 1132px) {
    .title_reviews {
      font-size: $typographyMediumBig;
    }
    .title_participate-cards {
      font-size: $typographyLargeMinus;
    }
    .title_map_participate {
      font-size: 30px;
    }
  }

  @media (max-width: 743px) {
    .main-text_map{
      font-size: $typographySmallPlus;
    }
    .title_regThanks {
      font-size: $typographyLargeMinus;
    }
    .main-text_participate-cards {
      font-size: $typographyMediumMinus;
    }
    .title_registration {
      font-size: $typographyMediumPlus;
    }
   .typography{
     color: var(--font-additional-color);
   }
    .title_map_participate {
      font-size: 24px;
    }
  }

  @media (max-width: 430px) {
    .general {
      font-size: $typographySmallPlus;
    }
  }
</style>
