export default {
  desktop: [
    {
      top: '203px',
      left: '380px',
      mode: 'bottomRight'
    },
    {
      top: '83px',
      left: '520px',
      mode: 'topRight'
    },
    {
      top: '153px',
      left: '660px',
      mode: 'rightBottom'
    },
    {
      top: '83px',
      left: '760px',
      mode: 'topRight'
    },
    {
      top: '110px',
      left: '960px',
      mode: 'topRight'
    },
    {
      top: '183px',
      left: '1080px',
      mode: 'bottomRight'
    },
    {
      top: '143px',
      left: '1278px',
      mode: 'topCenter'
    },
    {
      top: '179px',
      left: '1380px',
      mode: 'bottomRight'
    }
  ],
  'tablet-h': [
    {
      top: '120px',
      left: '60px',
      mode: 'bottomRight'
    },
    {
      top: '37px',
      left: '169px',
      mode: 'topRight'
    },
    {
      top: '126px',
      left: '298px',
      mode: 'rightBottom'
    },
    {
      top: '258px',
      left: '243px',
      mode: 'rightBottom'
    },
    {
      top: '370px',
      left: '427px',
      mode: 'rightBottom'
    },
    {
      top: '228px',
      left: '557px',
      mode: 'rightBottom'
    },
    {
      top: '130px',
      left: '686px',
      mode: 'topRight'
    },
    {
      top: '220px',
      left: '964px',
      mode: 'bottomRight'
    }
  ],
  'tablet-v': [
    {
      top: '-10px',
      left: '62px',
      mode: 'rightTopTwoLines'
    },
    {
      top: '61px',
      left: '142px',
      mode: 'rightTop'
    },
    {
      top: '145px',
      left: '222px',
      mode: 'rightTop'
    },
    {
      top: '165px',
      left: '462px',
      mode: 'rightTop'
    },
    {
      top: '355px',
      left: '462px',
      mode: 'bottomRight'
    },
    {
      top: '453px',
      left: '302px',
      mode: 'bottomRight'
    },
    {
      top: '406px',
      left: '122px',
      mode: 'rightCenterTwoLines'
    },
    {
      top: '518px',
      left: '62px',
      mode: 'bottomRight'
    }
  ],
  'mobile-xl': [
    {
      top: '69px',
      left: '287px',
      mode: 'topCenterTwoLines'
    },
    {
      top: '116px',
      left: '55px',
      mode: 'rightTop'
    },
    {
      top: '816px',
      left: '319px',
      mode: 'rightTop'
    },
    {
      top: '1417px',
      left: '187px',
      mode: 'rightTop'
    },
    {
      top: '2128px',
      left: '187px',
      mode: 'bottomRight'
    },
    {
      top: '3029px',
      left: '187px',
      mode: 'bottomRight'
    },
    {
      top: '3729px',
      left: '297px',
      mode: 'topCenterTwoLines'
    },
    {
      top: '3895px',
      left: '207px',
      mode: 'bottomCenterTwoLines'
    }
  ],
  'mobile-xs': [
    {
      top: '69px',
      left: '247px',
      mode: 'topCenterTwoLines'
    },
    {
      top: '116px',
      left: '35px',
      mode: 'rightTop'
    },
    {
      top: '816px',
      left: '279px',
      mode: 'rightTop'
    },
    {
      top: '1370px',
      left: '178px',
      mode: 'rightTop'
    },
    {
      top: '2049px',
      left: '40px',
      mode: 'bottomRight'
    },
    {
      top: '2953px',
      left: '137px',
      mode: 'bottomRight'
    },
    {
      top: '3729px',
      left: '247px',
      mode: 'topCenterTwoLines'
    },
    {
      top: '3895px',
      left: '157px',
      mode: 'bottomCenterTwoLines'
    }
  ]
}
