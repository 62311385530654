<template>
  <div class="container">
    <typography is="h2" :font="fonts.jostVariable" class="privacy-title">
      Политика конфиденциальности (условия обработки информации о пользователях)
    </typography>
    <div class="privacy-content">
      <privacy-section
        v-for="(privacy, index) in privacys"
        :key="index"
        :title="privacy.title"
        :paragraphs="privacy.content"
      />
    </div>
  </div>
</template>

<script>
import Typography from '@/components/Primitives/Typography'
import fonts from '@/themes/fonts'
import PrivacySection from '@/components/Primitives/PrivacySection'
import privacys from '@/configs/privacy'

export default {
  name: 'PrivacyPolicy',
  components: {
    Typography,
    PrivacySection
  },
  data () {
    return {
      fonts,
      privacys
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    width: 960px;
    padding-top: 215px;
  }

  .privacy-title {
    text-align: left;
    font-size: 30px;
    line-height: 39px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .privacy-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  @media (max-width: 1919px){
    .container {
      width: 1013px;
      padding-top: 169px;
    }

  }

  @media (max-width: 1132px) {
    .container {
      width: 620px;
    }
  }

  @media (max-width: 743px) {
    .privacy-title {
      font-size: 18px;
      text-transform: none;
      line-height: 23px;
    }
    .privacy-content {
      line-height: 21px;
    }
    .container {
      width: 300px;
      padding-top: 148px;
    }
  }
</style>
