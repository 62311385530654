<template>
  <div class="slider-block">
    <div class="slider" >
      <transition-group tag="div" :name="transitionName" class="slides-group">
        <div class="slider-container slide" :class="currentResSlides[slideIndex].className"  :key="currentResSlides[slideIndex].index" >
          <Slide :paper-patches="currentResSlides[slideIndex].slide.paperPatches" :backgrounds="currentResSlides[slideIndex].slide.backgrounds[type]" v-if="currentResSlides[slideIndex].show.includes(type)">
            <template v-slot:title>{{currentResSlides[slideIndex].slide.title}}</template>
            <template v-slot:teaser>{{currentResSlides[slideIndex].slide.teaser}}</template>
            <template v-slot:description>{{currentResSlides[slideIndex].slide.description}}</template>
            <template v-slot:time>{{currentResSlides[slideIndex].slide.time}}</template>
          </Slide>
        </div>
      </transition-group>
    </div>
    <div class="button-wrapper">
      <ButtonPrimitive mode="triangle-left" @click="slide(-1)" />
      <ButtonPrimitive mode="triangle-right" @click="slide(1)" />
    </div>
  </div>
</template>

<script>
import ButtonPrimitive from '@/components/Primitives/Button'
import Slide from '@/components/sections/Map/slides/SliderSlide'
import { useBreakpoints } from '@/utils/useBreakpoints'
export default {
  name: 'MapSliderBlock',
  components: {
    ButtonPrimitive,
    Slide
  },
  props: {
    slideIndex: Number
  },
  data: function () {
    return {
      slides: [
        {
          slide: {
            title: 'ПРИСКАЗКА исповеди',
            teaser: '',
            description: 'Открытие Проектной Исповеди 4.0, знакомство с новым уникальным форматом выступления спикеров и ключевые детали проведения конференции. Слушайте внимательно — именно от вас зависит судьба Тридевятого царства.',
            time: 'С 11:00 до 11:20',
            backgrounds: {
              desktop: ['https://storage.yandexcloud.net/cg-project-confession/default-slide'],
              'tablet-h': ['https://storage.yandexcloud.net/cg-project-confession/default-slide'],
              'tablet-v': ['https://storage.yandexcloud.net/cg-project-confession/default-slide']
            },
            paperPatches: []
          },
          index: 0,
          show: 'desktop',
          className: 'event-slide'
        },
        {
          slide: {
            title: 'Исследование',
            teaser: 'Препятствие первое: Баба-яга против!',
            description: 'Предпроектное исследование может уберечь проект от провала. В каких ситуациях нужно исследование и как использовать его в работе — расскажем на конференции.',
            time: 'С 11:20 до 12:00',
            backgrounds: {
              desktop: [
                'https://storage.yandexcloud.net/cg-project-confession/research-first-slide-desktop',
                'https://storage.yandexcloud.net/cg-project-confession/research-second-slide-desktop2.png'
              ],
              'tablet-h': [
                'https://storage.yandexcloud.net/cg-project-confession/research-first-slide-tablet-h',
                'https://storage.yandexcloud.net/cg-project-confession/research-second-slide-tablet-h2.png'
              ],
              'tablet-v': [
                'https://storage.yandexcloud.net/cg-project-confession/research-first-slide-tablet-v',
                'https://storage.yandexcloud.net/cg-project-confession/research-second-slide-tablet-v2.png'
              ]
            },
            paperPatches: [
              [
                {
                  name: 'Светлана Маркина',
                  description: 'Директор департамента ИТ-анализа в Ростелекоме',
                  positions:
                    {
                      desktop: { bottom: '20px', left: '90px' },
                      'tablet-h': { bottom: '20px', left: '10px' },
                      'tablet-v': { bottom: '280px', left: '30px' }
                    },
                  className: 'patch-default'
                },
                {
                  name: 'Алексей Грибанов',
                  description: 'Директор департамента архитектуры решений в Ростелекоме',
                  positions: {
                    desktop: { bottom: '110px', left: '360px' },
                    'tablet-h': { bottom: '45px', left: '290px' },
                    'tablet-v': { bottom: '300px', left: '380px' }
                  },
                  className: 'patch-large-alt'
                }
              ],
              [
                {
                  name: 'Мария Гостяева',
                  description: 'CX/UX-исследователь в Альфа-банке, преподаватель Alfa Academy',
                  positions: {
                    desktop: { bottom: '20px', left: '26px' },
                    'tablet-h': { bottom: '20px', left: '10px' },
                    'tablet-v': { bottom: '271px', left: '90px' }
                  },
                  className: 'patch-large'
                },
                {
                  name: 'Татьяна Минькова',
                  description: 'CX/UX-исследователь в Альфа-банке, преподаватель Alfa Academy',
                  positions: {
                    desktop: { bottom: '117px', left: '286px' },
                    'tablet-h': { bottom: '40px', left: '282px' },
                    'tablet-v': { bottom: '290px', left: '370px' }
                  },
                  className: 'patch-large'
                }
              ]
            ]
          },
          index: 1,
          show: 'desktop tablet-h tablet-v',
          className: 'testingSlide'
        },
        {
          slide: {
            title: 'Проектирование',
            teaser: 'Препятствие второе: Кощей над концептом чахнет…',
            description: 'Разберём, как лучше прорабатывать конечный образ продукта, что поможет донести его до команды и как прописать сильный экшн-план. ',
            time: 'С 12:00 до 12:40',
            backgrounds: {
              desktop:
                ['https://storage.yandexcloud.net/cg-project-confession/engineering-slide-desktop2.png'],
              'tablet-h':
                ['https://storage.yandexcloud.net/cg-project-confession/engineering-slide-tablet-h2.png'],
              'tablet-v':
                ['https://storage.yandexcloud.net/cg-project-confession/engineering-slide-tablet-v2.png']
            },
            paperPatches: [
              [
                {
                  name: 'Дарий Халитов',
                  description: 'Вице-президент по развитию информационных систем ПАО Ростелеком',
                  positions:
                    {
                      desktop: { bottom: '110px', left: '330px' },
                      'tablet-h': { bottom: '45px', left: '290px' },
                      'tablet-v': { bottom: '320px', left: '380px' }
                    },
                  className: 'patch-large'
                },
                {
                  name: 'Дамир Шафигулин',
                  description: 'Руководитель цифровых продуктов в Альфа-банке',
                  positions: {
                    desktop: { bottom: '20px', left: '60px' },
                    'tablet-h': { bottom: '20px', left: '10px' },
                    'tablet-v': { bottom: '280px', left: '30px' }
                  },
                  className: 'patch-small'
                }
              ]
            ]
          },
          index: 2,
          show: 'desktop tablet-h tablet-v',
          className: 'testingSlide'
        },
        {
          slide: {
            title: 'Дизайн',
            teaser: 'Препятствие третье: они могли создать шедевр из чего угодно',
            description: 'Сильный дизайн со всех сторон: как дизайнерам общаться с командой, проектировать пользовательский опыт и создавать дизайн-системы.',
            time: 'С 12:40 до 13:20',
            backgrounds: {
              desktop: ['https://storage.yandexcloud.net/cg-project-confession/design-first-slide-desktop',
                'https://storage.yandexcloud.net/cg-project-confession/design-second-slide-desktop'],
              'tablet-h': ['https://storage.yandexcloud.net/cg-project-confession/design-first-slide-tablet',
                'https://storage.yandexcloud.net/cg-project-confession/design-second-slide-tablet'],
              'tablet-v': ['https://storage.yandexcloud.net/cg-project-confession/design-first-slide-tablet-vertical',
                'https://storage.yandexcloud.net/cg-project-confession/design-second-slide-tablet-vertical']
            },
            paperPatches: [
              [
                {
                  name: 'Станислав Жибоедов',
                  description: 'Веб-дизайнер в Ростелекоме',
                  positions:
                    {
                      desktop: { bottom: '20px', left: '90px' },
                      'tablet-h': { bottom: '30px', left: '10px' },
                      'tablet-v': { bottom: '280px', left: '30px' }
                    },
                  className: 'patch-extra-small'
                },
                {
                  name: 'Никита Денисенко',
                  description: 'Дизайн-лид и продакт-оунер в Спектре, лауреат премии Red Dot',
                  positions: {
                    desktop: { bottom: '110px', left: '360px' },
                    'tablet-h': { bottom: '30px', left: '270px' },
                    'tablet-v': { bottom: '320px', left: '380px' }
                  },
                  className: 'patch-large'
                }
              ],
              [
                {
                  name: 'Денис Пушкарь',
                  description: 'Продакт-оунер в Ростелекоме. Сделал дизайн-системы СберЗвука и Ростелекома.',
                  positions: {
                    desktop: { bottom: '20px', left: '140px' },
                    'tablet-h': { bottom: '30px', left: '170px' },
                    'tablet-v': { bottom: '280px', left: '90px' }
                  },
                  className: 'patch-big'
                }
              ]
            ]
          },
          index: 3,
          show: 'desktop tablet-h tablet-v',
          className: 'testingSlide'
        },
        {
          slide: {
            title: 'Разработка',
            teaser: 'Препятствие четвёртое: выживи в проектном огне, как Жар-птица',
            description: 'Можно ли спланировать спринты так, чтобы не делать всё в последнюю ночь? — Да. Мы расскажем, как оценить ёмкость спринтов и учесть даже форс-мажоры.',
            time: 'С 13:20 до 14:00',
            backgrounds: {
              desktop: [
                'https://storage.yandexcloud.net/cg-project-confession/development-first-slide-desktop',
                'https://storage.yandexcloud.net/cg-project-confession/development-second-slide-desktop2.png',
                'https://storage.yandexcloud.net/cg-project-confession/development-third-slide-desktop'],
              'tablet-h': [
                'https://storage.yandexcloud.net/cg-project-confession/development-first-slide-tablet-h',
                'https://storage.yandexcloud.net/cg-project-confession/development-second-slide-tablet-h2.png',
                'https://storage.yandexcloud.net/cg-project-confession/development-third-slide-tablet-h'],
              'tablet-v': [
                'https://storage.yandexcloud.net/cg-project-confession/development-first-slide-tablet-v',
                'https://storage.yandexcloud.net/cg-project-confession/development-second-slide-tablet-v2.png',
                'https://storage.yandexcloud.net/cg-project-confession/development-third-slide-tablet-v']
            },
            paperPatches: [
              [
                {
                  name: 'Андрей Пахомов',
                  description: 'Руководитель группы по разработке цифровых продуктов в Ростелекоме ',
                  positions:
                    {
                      desktop: { bottom: '20px', left: '90px' },
                      'tablet-h': { bottom: '20px', left: '10px' },
                      'tablet-v': { bottom: '280px', left: '50px' }
                    },
                  className: 'patch-large-plus'
                },
                {
                  name: 'Юрий Плотников',
                  description: 'Тимлид бэкенд-разработки, системный архитектор в Ростелекоме',
                  positions: {
                    desktop: { bottom: '110px', left: '360px' },
                    'tablet-h': { bottom: '45px', left: '270px' },
                    'tablet-v': { bottom: '320px', left: '400px' }
                  },
                  className: 'patch-large'
                }
              ],
              [
                {
                  name: 'Ольга Головачева',
                  description: 'Директор ЦК по импортозамещению в Ростелекоме',
                  positions: {
                    desktop: { bottom: '20px', left: '90px' },
                    'tablet-h': { bottom: '20px', left: '10px' },
                    'tablet-v': { bottom: '280px', left: '50px' }
                  },
                  className: 'patch-large-plus'
                },
                {
                  name: 'Валерия Богданова',
                  description: 'Продакт-дизайнер в Center-Game',
                  positions: {
                    desktop: { bottom: '110px', left: '360px' },
                    'tablet-h': { bottom: '45px', left: '270px' },
                    'tablet-v': { bottom: '320px', left: '400px' }
                  },
                  className: 'patch-small-plus'
                }
              ],
              [
                {
                  name: 'Артур Гобеев',
                  description: 'Сооснователь Center-Game. Руководитель EdTech-проектов на 10 млн пользователей',
                  positions: {
                    desktop: { bottom: '20px', left: '90px' },
                    'tablet-h': { bottom: '20px', left: '270px' },
                    'tablet-v': { bottom: '280px', left: '200px' }
                  },
                  className: 'patch-big'
                }
              ]
            ]
          },
          index: 4,
          show: 'desktop tablet-h tablet-v',
          className: 'testingSlide'
        },
        {
          slide: {
            title: 'Тестирование',
            teaser: 'Препятствие пятое: в тихом омуте тестировщики водятся…',
            description: 'Тестирование поможет не упасть в грязь лицом в день релиза. Какую обратную связь собрать от аудитории и как её внедрить, если скоро релиз — поделимся на Исповеди.',
            time: 'С 14:00 до 14:40',
            backgrounds: {
              desktop: ['https://storage.yandexcloud.net/cg-project-confession/testing-slide-desktop'],
              'tablet-h': ['https://storage.yandexcloud.net/cg-project-confession/testing-slide-tablet-h'],
              'tablet-v': ['https://storage.yandexcloud.net/cg-project-confession/testing-slide-tablet-v']
            },
            paperPatches: [
              [
                {
                  name: 'Анастасия Радужная',
                  description: 'И. о. директора департамента тестирования и обеспечения качества в Ростелекоме',
                  positions:
                    {
                      desktop: { bottom: '20px', left: '60px' },
                      'tablet-h': { bottom: '20px', left: '10px' },
                      'tablet-v': { bottom: '280px', left: '30px' }
                    },
                  className: 'patch-large-plus'
                },
                {
                  name: 'Олег Королёв',
                  description: 'Руководитель отдела тестирования ЦК по внедрению и развитию CMS-решений в Ростелекоме',
                  positions: {
                    desktop: { bottom: '70px', left: '380px' },
                    'tablet-h': { bottom: '45px', left: '290px' },
                    'tablet-v': { bottom: '320px', left: '380px' }
                  },
                  className: 'patch-large-plus'
                }
              ]
            ]
          },
          index: 5,
          show: 'desktop tablet-h tablet-v',
          className: 'testingSlide'
        },
        {
          slide: {
            title: 'ТАЙНОЕ МЕРОПРИЯТИЕ',
            teaser: '',
            description: 'Самое интересное уже ждет вас. Не забывайте, ради чего вы здесь, будьте вместе и ничего не бойтесь — храбрость и отвага ваших сердец обязательно пригодятся.',
            time: 'С 14:40 до 14:50',
            backgrounds: {
              desktop: ['https://storage.yandexcloud.net/cg-project-confession/default-slide'],
              'tablet-h': ['https://storage.yandexcloud.net/cg-project-confession/default-slide'],
              'tablet-v': ['https://storage.yandexcloud.net/cg-project-confession/default-slide']
            },
            paperPatches: []
          },
          index: 6,
          show: 'desktop',
          className: 'event-slide'
        },
        {
          slide: {
            title: 'Концесловие исповеди',
            teaser: '',
            description: 'Вот и сказочке конец, а кто слушал — молодец! Результаты конференции, благодарности и пожелания — рассказываем судьбу заколдованного Тридевятого царства.',
            time: 'С 14:50 до 15:00',
            backgrounds: {
              desktop: ['https://storage.yandexcloud.net/cg-project-confession/default-slide'],
              'tablet-h': ['https://storage.yandexcloud.net/cg-project-confession/default-slide'],
              'tablet-v': ['https://storage.yandexcloud.net/cg-project-confession/default-slide']
            },
            paperPatches: []
          },
          index: 7,
          show: 'desktop',
          className: 'event-slide'
        }
      ],
      direction: 0,
      transitionName: 'slide-next'
    }
  },
  methods: {
    slide (dir) {
      this.direction = dir
      dir === 1
        ? (this.transitionName = 'slide-next')
        : (this.transitionName = 'slide-prev')
      const len = this.currentResSlides.length
      const currentIndex = (this.slideIndex + dir % len + len) % len
      this.$emit('changeSlide', currentIndex)
      setTimeout(() => { this.direction = 0 }, 0)
    }
  },
  computed: {
    currentResSlides () {
      return this.slides.filter((slide) => slide.show.includes(this.type))
    }
  },
  watch: {
    slideIndex (newIndex, oldIndex) {
      if (this.direction === 0) {
        if (newIndex > oldIndex) {
          this.transitionName = 'slide-next'
        } else {
          this.transitionName = 'slide-prev'
        }
      }
    }
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  }
}

</script>

<style lang="scss" scoped>

.slider {
  width: 100%;
  height: 529px;
  position: relative;
  overflow-x: hidden;
  z-index: 2;
}

.slide {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-next-enter-active{
  transform: translateX(120%);
}

.slide-next-leave-active{
  transform: translateX(0);
}

.slide-next-enter-to {
  transform: translateX(0);
}

.slide-next-leave-to {
  transform: translateX(-120%);
}

.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-prev-enter-active{
  transform: translateX(-120%);
}

.slide-prev-leave-active{
  transform: translateX(0);
}
.slide-prev-enter-to {
  transform: translateX(0);
}

.slide-prev-leave-to {
  transform: translateX(120%);
}

.slider-block {
  height: 729px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-flow: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 7px;
}

.slider-container{
  background-image: url("https://storage.yandexcloud.net/cg-project-confession/slider-container");
  width: 1528px;
  height: 529px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  overflow-x: hidden;
}

.button-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-top: 58px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 240px;
  z-index: 2;
}

.event-slide :deep(.slide){
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-self: center;
  align-self: center;

  & .text-wrapper{
   text-align: center;
   margin-right: 0;
   margin-top: 110px;
   width: 710px;

    & .title_slide{
      font-size: 54px;
      color: #FFEECC;
    }

  }
}

@media ( max-width: 1919px){
  .slider-block{
    height: 715px;
  }
  .slider-container{
    height: 480px;
    background-image: url("https://storage.yandexcloud.net/cg-project-confession/slider-container-tablet");
    width: 1133px;
  }
  .slider {
    height: 480px;
  }
}

  @media ( max-width: 1132px) {
    .slider-block {
      height: 967px;
    }
    .slider-container {
      height: 900px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/slider-container-tablet-vertical");
      width: 744px;
    }
    .slider {
      height: 900px;
    }
    .button-wrapper{
      margin-top: 25px;
    }
  }

</style>
