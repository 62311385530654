<template>
  <div class="schedule-block" id="map">
    <div class="river" ref="river"></div>
    <img src="@/assets/img/map-components/cross.svg" alt="отметка на карте" class="cross-image" ref="cross">
    <MapPoint
      :position="{top: pointsData[0].top, left: pointsData[0].left}"
      :time="{content: '11:00', mode: pointsData[0].mode }"
      :description="{content: 'Присказка Исповеди', mode: pointsData[0].mode }"
      data-top="50"
      data-left="35"
      data-river="10"
      ref="0"
      @click="(e) => handleClickWrapper(0)(e)"
    />
    <MapPoint
      :position="{top: pointsData[1].top, left: pointsData[1].left}"
      :time="{content: '11:20', mode: pointsData[1].mode }"
      :description="{content: 'Исследование', mode: pointsData[1].mode }"
      data-top="0"
      data-left="60"
      data-river="0"
      ref="1"
      @click="(e) => handleClickWrapper(1)(e)"
    />
    <MapPoint
      :position="{top: pointsData[2].top, left: pointsData[2].left}"
      :time="{content: '12:00', mode: pointsData[2].mode }"
      :description="{content: 'Проектирование', mode: pointsData[2].mode }"
      data-top="45"
      data-left="40"
      data-river="20"
      ref="2"
      @click="(e) => handleClickWrapper(2)(e)"
    />
    <MapPoint
      :position="{top: pointsData[3].top, left: pointsData[3].left}"
      :time="{content: '12:40', mode: pointsData[3].mode }"
      :description="{content: 'Дизайн', mode: pointsData[3].mode }"
      data-top="10"
      data-left="53"
      data-river="0"
      ref="3"
      @click="(e) => handleClickWrapper(3)(e)"
    />
    <MapPoint
      :position="{top: pointsData[4].top, left: pointsData[4].left}"
      :time="{content: '13:20', mode: pointsData[4].mode }"
      :description="{content: 'Разработка', mode: pointsData[4].mode }"
      data-top="-50"
      data-left="50"
      data-river="0"
      ref="4"
      @click="(e) => handleClickWrapper(4)(e)"
    />
    <MapPoint
      :position="{top: pointsData[5].top, left: pointsData[5].left}"
      :time="{content: '14:00', mode: pointsData[5].mode }"
      :description="{content: 'Тестирование', mode: pointsData[5].mode }"
      data-top="65"
      data-left="60"
      data-river="-20"
      ref="5"
      @click="(e) => handleClickWrapper(5)(e)"
    />
    <MapPoint
      :position="{top: pointsData[6].top, left: pointsData[6].left}"
      :time="{content: '14:40', mode: pointsData[6].mode }"
      :description="{content: 'Тайное мероприятие', mode: pointsData[6].mode }"
      data-top="-10"
      data-left="60"
      data-river="0"
      ref="6"
      @click="(e) => handleClickWrapper(6)(e)"
    />
    <MapPoint
      :position="{top: pointsData[7].top, left: pointsData[7].left}"
      :time="{content: '14:50', mode: pointsData[7].mode }"
      :description="{content: 'Концесловие Исповеди', mode: pointsData[7].mode }"
      data-top="55"
      data-left="-300"
      data-river="540"
      ref="7"
      @click="(e) => handleClickWrapper(7)(e)"
    />
  </div>
</template>

<script>
import MapPoint from '@/components/sections/Map/MapPoint'
import { useBreakpoints } from '@/utils/useBreakpoints'
import pointsDataMap from '@/themes/pointsDataMap'

export default {
  name: 'MapScheduleBlock',
  data: function () {
    return {
      pointsDataMap
    }
  },
  emits: ['changeSlide'],
  props: {
    slideIndex: Number
  },
  components: {
    MapPoint
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  },
  computed: {
    pointsData () {
      return this.pointsDataMap[this.type]
    }

  },
  methods: {
    handleClick (e) {
      this.$refs.cross.classList.remove('cross')
      this.$refs.river.style.width = e.target.offsetLeft + +e.target.dataset.river + 'px'
      this.$refs.cross.style.left = e.target.offsetLeft - e.target.dataset.left + 'px'
      this.$refs.cross.style.top = e.target.offsetTop - e.target.dataset.top + 'px'
      this.$refs.cross.classList.add('cross')
    },
    handleClickWrapper (num) {
      return (e) => {
        this.handleClick(e)
        if (this.type === 'desktop') {
          this.$emit('changeSlide', num)
        }
      }
    },
    handleSliderChange () {
      this.$refs.cross.classList.remove('cross')
      this.$refs.river.style.width = this.$refs[this.slideIndex].$el.offsetLeft + +this.$refs[this.slideIndex].$el.dataset.river + 'px'
      this.$refs.cross.style.left = this.$refs[this.slideIndex].$el.offsetLeft - this.$refs[this.slideIndex].$el.dataset.left + 'px'
      this.$refs.cross.style.top = this.$refs[this.slideIndex].$el.offsetTop - this.$refs[this.slideIndex].$el.dataset.top + 'px'
      this.$refs.cross.classList.add('cross')
    }
  },
  watch: {
    slideIndex () {
      this.handleSliderChange()
    }
  },
  mounted () {
    this.handleSliderChange()
  }

}
</script>

<style lang="scss" scoped>
.schedule-block {
  height: 340px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    z-index: -3;
    inset: 0;
    background-color: #ffffff;
  }
  .river {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    border-radius: 100px;
    left: 0;
    width: 0;
    transition: .5s width;
    background-color: #537636;
  }
}

.cross-image {
  position: absolute;
  left: 0;
  top: 70px;
}

.cross {
  animation: cross .5s forwards;
}

@keyframes cross {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1919px) {
  .schedule-block {
    height: 530px;
    width: 1133px;
    margin: auto;

    & img {
      display: none;
    }
  }
}

@media (max-width: 1132px) {
  .schedule-block {
    height: 700px;
    width: 744px;
    margin: auto;
  }
}
</style>
