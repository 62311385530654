<template>
    <button class="map-point custom-cursor" id="map" :style="cssProps" :data-time-content="dataAtrrs.timeContent" :data-description-content="dataAtrrs.descriptionContent"/>
</template>

<script>

export default {
  name: 'MapPoint',
  data: function () {
    return {
      timePositionMap: {
        topCenter: { top: '-64px', left: '-94px' },
        topRight: { top: '-50px', left: '20px' },
        bottomRight: { top: '20px', left: '0' },
        rightBottom: { top: '0px', left: '20px' },
        rightTop: { top: '-55px', left: '0px' },
        rightTopTwoLines: { top: '-80px', left: '0px' },
        topCenterTwoLines: { top: '-80px', left: '-20px' },
        bottomCenterTwoLines: { top: '22px', left: '-18px' },
        rightCenterTwoLines: { top: '-8px', left: '20px' }
      },
      descriptionPositionMap: {
        topCenter: { top: '-36px', left: '-94px' },
        topRight: { top: '-22px', left: '20px' },
        bottomRight: { top: '50px', left: '0' },
        rightBottom: { top: '30px', left: '20px' },
        rightTop: { top: '-29px', left: '0px' },
        rightTopTwoLines: { top: '-55px', left: '0px' },
        topCenterTwoLines: { top: '-55px', left: '-68px' },
        bottomCenterTwoLines: { top: '42px', left: '-66px' },
        rightCenterTwoLines: { top: '20px', left: '20px' }
      }
    }
  },
  components: {
  },
  props: {
    position: Object,
    time: Object,
    description: Object
  },
  computed: {
    cssProps () {
      return {
        '--top': this.position.top,
        '--left': this.position.left,
        '--time-content': this.time.content,
        '--description-content': this.description.content,
        '--time-top': this.timePositionMap[this.time.mode].top,
        '--time-left': this.timePositionMap[this.time.mode].left,
        '--description-top': this.descriptionPositionMap[this.description.mode].top,
        '--description-left': this.descriptionPositionMap[this.description.mode].left
      }
    },
    dataAtrrs () {
      return {
        timeContent: this.time.content,
        descriptionContent: this.description.content
      }
    }
  }

}
</script>

<style lang="scss" scoped>
 .map-point{
   border: none;
   will-change: transform;
   transition: .3s transform;
   width: 10px;
   height: 10px;
   border-radius: 50%;
   background-color: #FFEECC;
   position: absolute;
   top: var(--top);
   left: var(--left);

   &:after {
     display: block;
     content: attr(data-time-content);
     top: var(--time-top);
     left: var(--time-left);
     color: $white;
     text-align: start;
     position: absolute;
     font-size: $typographyMedium;
     font-family: "Jost Medium";
   }

   &:before {
     content: attr(data-description-content);
     top: var(--description-top);
     left: var(--description-left);
     color: $white;
     position: absolute;
     text-align: start;
     font-size: $typographyMedium;
     font-family: "Jost Medium";
     max-width: 215px;
     width: max-content;
   }

   &:hover {
     transform: scale(1.1);
   }
 }

 @media (max-width: 1919px) {
   .custom-cursor {
     cursor: default;
   }
   .map-point{
     &:after{
       font-size: $typographyMediumMinus;
     }
     &:before{
       font-size: $typographyMediumMinus;
       width: 169px;
     }
     &:hover {
       transform: scale(1);
     }
   }
 }

 @media (max-width: 743px) {
  .map-point{
    width: 16px;
    height: 16px;
    background-color: #FFEECC;

    &:before{
      width: 150px;
      text-align: center;
    }
  }
 }
</style>
