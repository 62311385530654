<template>
  <div class="project-management-section section" >
    <div class="section-container">
      <div class="title-container" ref="title" >
        <MultilineTitle
          class="section-title"
          :lines="['управление ИТ - проектами', '— не сказка']"
        />
      </div>

      <div class="content" ref="content">
        <typography-primitive is="span" class="story-ahead">
          Сказка впереди...
        </typography-primitive>
        <div class="paragraphs">
          <p class="paragraph first-paragraph" ref="first-paragraph">
            {{ paragraphs[0] }}
          </p>
          <p class="paragraph second-paragraph" ref="second-paragraph">
            {{ paragraphs[1] }}
          </p>
          <p class="paragraph third-paragraph" ref="third-paragraph" >
            {{ paragraphs[2] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultilineTitle from '@/components/Primitives/MultilineTitle'
import TypographyPrimitive from '@/components/Primitives/Typography'

export default {
  name: 'ProjectManagementSection',
  components: {
    TypographyPrimitive,
    MultilineTitle
  },
  data () {
    return {
      paragraphs: [
        `Змей Горыныч наслал на наше царство проклятье горящих проектов.
           Мы горим на всех этапах, а царство погружается во мрак.`,
        `Говорят, в дремучем лесу живут Хранители: опытные менеджеры, руководители проектов,
          исследователи, дизайнеры и разработчики. Они открыто расскажут о проектных
          проблемах и их решениях.`,
        `Теперь мы бьём челом перед вами. Примерьте на себя роль доброго молодца Олега,
          чтобы пересечь дремучий лес, узнать секреты проектной деятельности, победить Змея
          и спасти царство ИТ-проектов.`
      ]
    }
  },
  methods: {
    showContent () {
      if (document.documentElement.scrollTop <= 150) return
      this.$refs.title.classList.add('show')
      this.$refs['first-paragraph'].classList.add('show')
      this.$refs['second-paragraph'].classList.add('show')
      this.$refs['third-paragraph'].classList.add('show')
      this.$refs.content.classList.add('show')
      window.removeEventListener('scroll', this.showContent)
    }
  },

  mounted () {
    window.addEventListener('scroll', this.showContent)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.showContent)
  }
}
</script>

<style lang="scss" scoped>
  .project-management-section {
    z-index: 1000;
    position: relative;
    top: 0;
    bottom: 0;
    height: 601px;

    &:after {
      position: absolute;
      top: 0;
      height: 2000px;
    }
  }

  .section-container, .content {
    position: relative;
    z-index: 100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .section-container {
    position: relative;
    top: -1.5rem;
    margin: 0 23.125% 0 23.125%;
  }
  .section-title {
    margin-left: -90px;
    margin-right: 50px;
    margin-bottom: 0.625rem;
    z-index: 10;
  }

  .title-container {
    opacity: 0;
    animation-delay: .1s;
  }

  .show {
    animation: show .2s forwards ease-in;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .content {
    margin-right: 65px;
    opacity: 0;
    animation-delay: .3s;
    width: 50%;
    text-align: left;
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }
  .story-ahead {
    font-size: 22px;
    margin-bottom: 2.5rem;
  }
  .paragraph {
    opacity: 0;
    color: #101010;
    font-size: 22px;
    font-family: "Jost Medium";
    position: relative;
    z-index: 3;
    width: 660px;
    line-height: 29px;
  }

  .first-paragraph {
    animation-delay: .4s;
  }

  .second-paragraph {
    animation-delay: .5s;
  }

  .third-paragraph {
    animation-delay: .6s;
  }

  .paragraphs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    position: relative;

    & p:nth-of-type(n){
      margin-bottom: 20px;
    }

    & p:last-of-type{
      margin-bottom: 0;
    }

    &:after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      position: absolute;
      background: #FAFFF6;
      opacity: .4;
      filter: blur(42.5px);
      -webkit-filter: blur(42.5px);
      content: '';
    }
  }
  @media (max-width: 1919px) {
    .paragraph {
      font-size: 16px;
      width: 490px;
    }
    .section-title {
      margin-left: 0;
      margin-right: 0;
    }
    .project-management-section {
      position: relative;
      z-index: 11;
      height: 880px;
      background-image: none;
      max-width: 1440px;
    }
    .story-ahead {
      font-size: 16px;
      margin-bottom: 70px;
    }
    .section-container {
      top: 5rem;
      margin: 0 60px 0 60px;
    }
    .content {
      margin-right: 0;
    }
  }

    @media (max-width: 1132px) {
      .project-management-section {
        max-width: 960px;
        height: 542px;
        background-image: none;
      }
      .section-title {
        width: 618px;
        margin: 0 auto 15px auto;
      }
      .section-container {
        top: 1.5rem;
      }
      .story-ahead {
        margin-bottom: 45px;
        font-size: 18px;
      }
      .content {
        width: 460px;
      }
      .paragraph {
        font-size: 18px;
      }

      .paragraphs {
        margin-top: 10px;
        width: 460px;
        position: relative;

      &:after {
        top: 10%;
        right: 10%;
        bottom: 10%;
        left: 10%;
      }
    }
  }

    @media (max-width: 850px) {
      .content {
        width: 460px;
      }
    }

    @media (max-width: 743px) {
      .project-management-section {
        max-width: 430px;
        height: 660px;
        background-image: none;
      }
      .paragraph {
        line-height: 23px;
      }
      .section-container {
        display: block;
        top: 1.5rem;
        margin: 0 60px 0 60px;
      }
      .content {
        width: inherit;
      }
      .section-container {
        top: 0;
        width: 310px;
        margin: 0 auto;
      }
      .paragraph {
        width: inherit;
      }
      .paragraphs {
        width: inherit;
      }
      .section-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 310px;
        -webkit-align-items: center;
        align-items: center;
      }
    }
</style>
