<template>
  <div class="serpent-section section" ref="section">
    <div class="left-rock" ref="left-rock" rel="preload"></div>
    <div class="right-rock" ref="right-rock" rel="preload"></div>
    <div class="wood" ref="wood" rel="preload"></div>
    <div class="serpent" ref="serpent" rel="preload"></div>
    <div class="right-cloud" ref="right-cloud" rel="preload"></div>
    <div class="left-cloud" ref="left-cloud" rel="preload"></div>
    <div class="hero" ref="hero" rel="preload"></div>
    <Village ref="village"/>
    <div class="inner-container" ref="text">
      <div class="text-wrapper">
        <Typography is="p">Ежегодная онлайн-конференция <br class="line-breaker"> от ИТ-команды Ростелекома</Typography>
        <Typography is="h1" :mode="typographyMode.title_serpent" color="#FFEECC" :font="fonts.balkaraCondensed" >Проектная исповедь 4.0</Typography>
        <Typography is="p">
          О том, как управлять ИТ-проектами и справляться со сложностями.<br>
          Открыто, честно, без воды и рекламы.
        </Typography>
        <Typography is="p">18 ноября 2022 года</Typography>
      </div>
    </div>
  </div>
</template>

<script>
import Village from '@/components/Primitives/Village'
import speeds from '@/configs/serpentParallaxSpeeds'
import Typography from '@/components/Primitives/Typography'
import { typographyMode } from '@/themes/primitivesModes'
import fonts from '@/themes/fonts'
import { useBreakpoints } from '@/utils/useBreakpoints'

export default {
  name: 'SerpentSection',
  data: function () {
    return {
      typographyMode,
      fonts
    }
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  },
  components: {
    Typography,
    Village
  },
  methods: {
    getTop (elem) {
      return parseInt(window.getComputedStyle(elem).top)
    },
    serpentReaction (e) {
      if (this.type !== 'desktop') return
      if (document.documentElement.scrollTop > 80) return
      const x = e.clientX / document.documentElement.offsetWidth
      const y = e.clientY / document.documentElement.offsetHeight
      this.$refs.serpent.style.transform = `translate(-${x * (x / 4 * 200)}px, -${y * 200}px)`
    },
    parallax () {
      const scrollTop = document.documentElement.scrollTop

      if (scrollTop > 1500) return

      const serpentTop = this.getTop(this.$refs.serpent)
      const heroTop = this.getTop(this.$refs.hero)
      const leftRockTop = this.getTop(this.$refs['left-rock'])
      const rightRockTop = this.getTop(this.$refs['right-rock'])
      const woodTop = this.getTop(this.$refs.wood)
      const leftCloudTop = this.getTop(this.$refs['left-cloud'])
      const rightCloudTop = this.getTop(this.$refs['right-cloud'])
      const textTop = this.getTop(this.$refs.text)

      this.$refs.serpent.style.transform = `translateY(-${serpentTop + scrollTop / speeds[this.type].serpent}px`
      this.$refs['left-cloud'].style.transform = `translateY(-${leftCloudTop + scrollTop * speeds[this.type]['left-cloud']}px`
      this.$refs['right-cloud'].style.transform = `translateY(-${rightCloudTop + scrollTop * speeds[this.type]['right-cloud']}px`
      this.$refs.wood.style.transform = `translateY(-${woodTop + scrollTop / speeds[this.type].wood}px`
      this.$refs.hero.style.transform = `translateY(-${heroTop + scrollTop * speeds[this.type].hero}px`
      this.$refs.text.style.transform = `translateY(-${textTop + scrollTop / speeds[this.type].text}px`
      this.$refs['left-rock'].style.transform = `translateY(-${leftRockTop + scrollTop / speeds[this.type]['left-rock']}px`
      this.$refs['right-rock'].style.transform = `translateY(-${rightRockTop + scrollTop / speeds[this.type]['right-rock']}px`
    }
  },
  watch: {
    type () {
      this.$refs.serpent.style.transform = 'none'
    }
  },
  mounted () {
    window.addEventListener('scroll', this.parallax)
    window.addEventListener('mousemove', this.serpentReaction)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.parallax)
    window.removeEventListener('mousemove', this.serpentReaction)
  }
}
</script>

<style lang="scss" scoped>
  .serpent-section {
    will-change: transform;
    position: relative;
    max-width: 1920px;
    z-index: 2;
    height: 1053px;
    background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-bg-desktop");
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-flow: column;
    -webkit-align-items: center;
    align-items: center;
  }

  .left-rock, .right-rock, .wood, .serpent, .hero, .right-cloud, .left-cloud {
    will-change: transform;
    position: absolute;
    transition: .2s linear;
    top: 0;
    width: 2048px;
    height: 1426px;
  }
  .left-rock {
    z-index: 3;
    background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-left-rock-desktop");
  }
    .right-rock {
      z-index: 4;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-right-rock-desktop");
    }

    .wood {
      z-index: 5;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-wood-desktop");
    }

    .serpent {
      z-index: 6;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-serpent-desktop");
    }

    .right-cloud {
      background-repeat: no-repeat;
      right: 0;
      width: 1037px;
      z-index: 7;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-right-cloud-desktop");
    }

    .left-cloud {
      width: 1011px;
      background-repeat: no-repeat;
      left: 0;
      z-index: 8;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-left-cloud-desktop");
    }

    .hero {
      height: 2500px;
      background-repeat: no-repeat;
      z-index: 10;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-hero-desktop");
    }

    .inner-container {
      top: 0;
      position: relative;
      z-index: 100;
      transition: .2s linear;
      margin-top: 320px;
      height: 397px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-flow: column;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-align-items: center;
      align-items: center;
    }

    .text-wrapper {
      position: relative;
      z-index: 9;
      height: 282px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-flow: column;
      -webkit-justify-content: space-between;
      justify-content: space-between;
    }

    .line-breaker{
      display: none;
    }

    @media (max-width: 1919px) {
      .left-rock, .right-rock, .wood, .serpent, .hero, .right-cloud {
        background-repeat: no-repeat;
        height: 1237.92px;
        width: 1440px;
        background-position: center;
        transition: .1s linear;
      }

      .left-rock {
        z-index: 3;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-left-rock-tablet");
      }

      .right-rock {
        z-index: 4;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-right-rock-tablet");
      }
      .wood {
        z-index: 5;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-wood-tablet");
      }
      .serpent {
        z-index: 6;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-serpent-tablet");
      }
      .right-cloud {
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        z-index: 7;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-clouds-tablet");
      }
      .left-cloud {
        background-image: none;
      }
      .hero {
        background-repeat: no-repeat;
        height: 4128px;
        background-position: 0 150px;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-hero-tablet");
      }

      .serpent-section {
        max-width: 1440px;
        height: 945px;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-bg-tablet");
      }
      .inner-container {
        transition: .1s linear;
        height: 430px;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        margin-top: 300px;
      }
      .text-wrapper {
        height: 197px;
        margin-bottom: 40px;
      }
    }

    @media (max-width: 1132px) {
      .left-rock, .right-rock, .wood, .serpent, .hero, .right-cloud {
        background-repeat: no-repeat;
        height: 1018px;
        width: 744px;
        background-position: center;
      }
      .left-rock {
        z-index: 3;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-left-rock-tablet-vertical");
      }

      .right-rock {
        z-index: 4;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-right-rock-tablet-vertical");
      }
      .wood {
        z-index: 5;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-wood-tablet-vertical");
      }
      .serpent {
        z-index: 6;
        top: -15px;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-serpent-tablet-vertical");
      }
      .right-cloud {
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        z-index: 7;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-clouds-tablet-vertical");
      }
      .left-cloud {
        background-image: none;
      }
      .hero {
        top: 0;
        height: 2489px;
        width: 744px;
        z-index: 9;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-hero-tablet-vertical");
      }
      .serpent-section {
        max-width: 960px;
        height: 744px;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-bg-tablet-vertical");
      }
      .inner-container {
        margin-top: 175px;
        height: 360px;
      }
      .text-wrapper {
        height: 210px;

        & p:nth-of-type(n){
          margin-bottom: 20px;
        }

        & h1:first-of-type{
          margin-bottom: 20px;
        }

        & p:last-of-type{
          margin-bottom: 0;
        }
      }

    }

    @media (max-width: 743px) {
      .left-rock, .right-rock, .wood, .serpent, .hero, .right-cloud {
        background-repeat: no-repeat;
        height: 1024px;
        width: 430px;
        background-position: center;
      }
      .left-rock {
        z-index: 3;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-left-rock-phone");
      }

      .right-rock {
        z-index: 4;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-right-rock-phone");
      }
      .wood {
        z-index: 5;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-wood-phone");
      }
      .serpent {
        z-index: 6;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-serpent-phone");
      }
      .right-cloud {
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        z-index: 7;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-clouds-phone");
      }
      .left-cloud {
        background-image: none;
      }
      .hero {
        width: 430px;
        height: 2942px;
        z-index: 9;
        background-position: 0 -50px;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-hero-phone");
      }
      .serpent-section {
        height: 796px;
        background-image: url("https://storage.yandexcloud.net/cg-project-confession/parallax-bg-phone");
      }
      .inner-container {
        width: 320px;
        height: 450px;
        margin-top: 170px;
      }
      .text-wrapper {
        height: 262px;
        margin-bottom: 50px;

        & h1:first-of-type {
          margin-bottom: 10px;
        }
      }

      .line-breaker{
        display: inline;
      }
    }
</style>
