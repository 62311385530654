export default {
  desktop: {
    village: 0,
    'left-cloud': 3,
    'right-cloud': 7,
    serpent: 1,
    wood: 5,
    hero: 1,
    text: 1.2,
    'left-rock': 10,
    'right-rock': 3
  },
  'tablet-h': {
    village: 0,
    'left-cloud': 3,
    'right-cloud': 3,
    serpent: 1,
    wood: 1.5,
    hero: 1.2,
    text: 1.3,
    'left-rock': 1.5,
    'right-rock': 1.7
  },
  'tablet-v': {
    village: 0,
    'left-cloud': 3,
    'right-cloud': 5,
    serpent: 1.5,
    wood: 2,
    hero: 1,
    text: 1.5,
    'left-rock': 1.5,
    'right-rock': 1.3
  },
  'mobile-xl': {
    village: 0,
    'left-cloud': 3,
    'right-cloud': 5,
    serpent: 1.2,
    wood: 2,
    hero: 0.8,
    text: 2,
    'left-rock': 10,
    'right-rock': 5
  },
  'mobile-xs': {
    village: 0,
    'left-cloud': 3,
    'right-cloud': 5,
    serpent: 1.2,
    wood: 2,
    hero: 0.8,
    text: 2,
    'left-rock': 10,
    'right-rock': 5
  }
}
