export const typographyMode = {
  title_serpent: 'title_serpent',
  title_participateCards: 'title_participate-cards',
  title_registration: 'title_registration',
  title_slideTime: 'title_slide-time',
  title_counterData: 'title_counter-data',
  title_reviews: 'title_reviews',
  title_map_participate: 'title_map_participate',
  title_regThanks: 'title_regThanks',
  general: 'general',
  mainText_ParticipateCards: 'main-text_participate-cards',
  mainText_counterData: 'main-text_counter-data',
  addText_slideSpeakerName: 'add-text_slide-speaker-name',
  addText_footer_slideSpeakerInfo: 'add-text_footer_slide-speaker-info',
  title_slide: 'title_slide',
  mainText_slide: 'main-text_slide',
  title_mobileSlide: 'title_mobile-slide',
  title_mobileSlideTime: 'title_mobile-slide-time',
  mainText_mobileSlide: 'main-text_mobile-slide',
  mainText_map: 'main-text_map',
  addText_registrationValidation: 'add-text_registration-validation',
  program_title: 'program_title'
}

// типТекста_местоИспользования_местоИспользования
