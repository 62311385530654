<template>
  <button :style="cssProps" :class="mode" class="button custom-cursor"><slot /></button>
</template>

<script>
import colors from '@/themes/colors'
import fonts from '@/themes/fonts'

export default {
  name: 'ButtonPrimitive',
  components: {
  },
  props: {
    mode: { type: String, default: 'rectangle' },
    size: { type: String, default: '$typographyMediumMinus' },
    color: { type: String, default: colors.white },
    font: { type: String, default: fonts.jostVariable }
  },
  computed: {
    cssProps () {
      return {
        '--font-size': this.size,
        '--font-color': this.color,
        '--font-family': this.font
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button{
  font-weight: 400;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
}

.rectangle{
  background-color: $green;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 16px 60px;
  border: none;
  transition: background-color 300ms;
}

.rectangle:hover{
  background-color: $red;
}

.triangle-right{
  background-image: url("@/assets/img/map-components/triangle-button.svg");
  width: 56px;
  height: 42px;
  background-color: transparent;
  border: none;

  &:hover{
    background-image: url("@/assets/img/map-components/triangle-button-hover.svg");
  }
}

.triangle-left {
  background-image: url("@/assets/img/map-components/triangle-button.svg");
  width: 56px;
  height: 42px;
  background-color: transparent;
  border: none;
  transform: rotate(180deg);
  &:hover{
    background-image: url("@/assets/img/map-components/triangle-button-hover.svg");
  }
}

.circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FFEECC;
  border: none;
  margin-right: 18px;
}

@media (max-width: 1133px) {
  .rectangle{
    padding: 13px 60px;
  }
}

</style>
