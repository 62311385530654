<template>
    <div class="container">
      <SerpentParallax />
      <Map />
      <ParticipateWays />
      <Reviews />
    </div>
</template>

<script>
import SerpentParallax from '@/components/sections/SerpentParallax'
import Map from '@/components/sections/Map'
import Reviews from '@/components/sections/Reviews/Reviews'
import ParticipateWays from '@/components/sections/ParticipateWays/ParticipateWays'

export default {
  name: 'HomeView',
  components: {
    SerpentParallax,
    Map,
    Reviews,
    ParticipateWays
  }
}
</script>

<style lang="scss">
  .section {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .container {
    overflow: hidden;
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-flow: column;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1920px;
    overflow-x: hidden;
  }

  @media (max-width: 1919px){
    .container {
      max-width: 1440px;
    }
  }

  @media (max-width: 1132px) {
    .container {
      max-width: 744px;
    }
  }

  @media (max-width: 743px) {
    .container {
      max-width: 430px;
    }
  }
</style>
