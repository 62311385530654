<template>
  <div class="reviews-section section">
    <div id="reviews" class="anchor" />
    <typography is="h2" :mode="typographyMode.title_reviews" color="#FFEECC" :font="fonts.jostVariable" class="reviews-section__header">
      Что говорят об Исповеди
    </typography>
    <div class="reviews-container">
      <div class="reviews-inner">
        <div class="reviews-inner__left-side">
          <review-component class="first-review">
            <template #content>
              {{ reviews[0].content }}
            </template>
            <template #author>
              {{ reviews[0].author }}
            </template>
          </review-component>
          <review-component class="second-review">
            <template #content>
              {{ reviews[2].content }}
            </template>
            <template #author>
              {{ reviews[2].author }}
            </template>
          </review-component>
        </div>
        <div class="reviews-inner__right-side">
          <review-component class="third-review">
            <template #content>
              {{ reviews[1].content }}
            </template>
            <template #author>
              {{ reviews[1].author }}
            </template>
          </review-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fonts from '@/themes/fonts'
import Typography from '@/components/Primitives/Typography'
import { typographyMode } from '@/themes/primitivesModes'
import ReviewComponent from '@/components/Primitives/Review'

export default {
  name: 'ReviewsSection',
  components: {
    Typography,
    ReviewComponent
  },
  data () {
    return {
      fonts,
      typographyMode,
      reviews: [
        {
          content: 'Впечатлило проставление оценок онлайн на глазах у спикера. Рискованно, но круто!',
          author: 'Антон, IT-разработчик'
        },
        {
          content: 'Креативный подход! Драматический сценарий и аналогия с академическим' +
            ' театральным представлением — удерживают внимание и поднимают интерес, вызывают' +
            ' дополнительные эмоции, позволяют сопереживать участникам проектов и усиливают' +
            ' запоминание материала и данного события. Ростелеком — крутой!',
          author: 'Алина, проектный менеджер'
        },
        {
          content: 'Крутая идея, зажигательные рассказы спикеров. Это круче, чем офлайн!',
          author: 'Станислав, проектный менеджер'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
  .reviews-section {
    position: relative;
    z-index: 6;
    padding-top: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    height: 900px;
    background-image: url('https://storage.yandexcloud.net/cg-project-confession/reviews-desktop');
  }

  .reviews-section__header {
    margin-left: 380px;
    -webkit-align-self: flex-start;
    align-self: flex-start;
    text-transform: uppercase;
    margin-bottom: 60px;
  }

  .reviews-container {
    padding-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    height: 500px;
    width: 1530px;
    background-repeat: no-repeat;
    background-image: url('https://storage.yandexcloud.net/cg-project-confession/reviews-container-desktop');
  }

  .reviews-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 1220px;
    height: 325px;
  }

  .reviews-inner__left-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 465px;
    height: 315px;
    margin-right: 140px;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .reviews-inner__right-side {
    width: 585px;
    height: 270px;
  }

  .first-review{
    margin-bottom: 60px;
  }

  .first-review .content {
    width: 440px;
  }

  .first-review .author-section {
    width: 281px;
  }

  .second-review .author-section {
    width: 399px;
  }

  .third-review {
    width: 560px;
  }

  .third-review .content {
    width: 555px
  }

  .third-review .author-section {
    width: 350px;
  }

  .anchor{
    position: absolute;
    top: -100px;
    width: 0;
    height: 0;
  }

  @media (max-width: 1919px) {
    .reviews-section {
      height: 530px;
      background-size: cover;
      background-image: url('https://storage.yandexcloud.net/cg-project-confession/reviews-tablet');
      padding-top: 95px;
    }
    .reviews-container {
      height: 410px;
      padding-top: 60px;
      width: 1133px;
      background-image: url('https://storage.yandexcloud.net/cg-project-confession/reviews-container-tablet');
    }
    .reviews-inner {
      width: 1035px;
    }
    .reviews-section__header {
      margin-left: 60px;
      margin-bottom: 58px;
    }
    .reviews-inner__left-side {
      margin-left: -20px;
      width: 468px;
      margin-right: 60px;
    }
    .author-section {
      font-size: 18px;
    }

    .first-review{
      margin-right: 62px;
    }

    .first-review .content {
      width: 360px;
    }
    .first-review .author-section {
      width: 232px;
    }
    .second-review .content {
      width: 430px;
    }

    .second-review .author-section {
      width: 340px;
    }

    .third-review {
      width: 520px;
    }
    .third-review .content {
      width: 490px;
    }
    .third-review .author-section {
      width: 300px;
    }
  }

  @media (max-width: 1132px) {
    .reviews-section {
      height: 760px;
      padding-top: 0;
      background-image: url('https://storage.yandexcloud.net/cg-project-confession/reviews-tablet-vertical');
      background-size: cover;
    }
    .reviews-container {
      height: 550px;
      width: 744px;
      background-image: url('https://storage.yandexcloud.net/cg-project-confession/reviews-container-tablet-vertical');
    }
    .reviews-section__header {
      margin-left: 62px;
    }
    .reviews-inner__left-side {
      margin-right: 0px;
      margin-bottom: 40px;
    }
    .reviews-inner {
      width: 650px;
      margin-left: 10px;
      -webkit-flex-direction: column;
      flex-direction: column;
    }
    .first-review{
      margin-bottom: 40px;
    }

    .first-review .content {
      width: 530px;
    }
    .first-review .author-section {
      box-sizing: border-box;
      padding-left: 10px;
    }

    .second-review .content {
      width: 650px;
    }
    .third-review .content {
      width: 620px;
    }
    .third-review {
      width: 650px;
      margin-left: -20px;
    }
  }

  @media (max-width: 743px) {
    .reviews-section {
      width: 430px;
      height: 1012px;
      padding-top: 0;
      background-size: auto;
      background-image: url('https://storage.yandexcloud.net/cg-project-confession/reviews-phone');
    }
    .reviews-container {
      box-sizing: border-box;
      height: 843px;
      width: 320px;
      padding-left: 30px;
      background-image: url('https://storage.yandexcloud.net/cg-project-confession/reviews-container-phone');
    }
    .reviews-section__header {
      line-height: 39px;
      margin-left: 65px;
      text-align: left;
      margin-bottom: 30px;
      width: 220px;
    }
    .reviews-inner__left-side, .reviews-inner__right-side {
      width: 280px;
    }
    .reviews-inner {
      width: 280px;
    }
    .reviews-inner__left-side{
      margin-bottom: 58px;
    }

    .content {
      width: 280px;
      margin-left: 0;
    }
    .third-review {
      width: 280px;
    }
    .first-review .author-section {
      box-sizing: border-box;
      padding-right: 25px;
      width: 239px;
    }
    .second-review .author-section {
      width: 239px;
      height: 70px;
    }
    .third-review .author-section {
      width: 239px;
      height: 70px;
    }
  }
</style>
