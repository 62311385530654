<template>
  <div class="participate-ways-section section">
    <video-background
      src="/participate-ways-animation.webm"
      :sources="[
          {src: '/participate-ways-animation-tablet.webm', res: '1919', autoplay: false, loop: false}
      ]"
      ref="raven"
      class="raven-background"
      @ended="stopVideo"
      :autoplay="false"
      :loop="false"
      :poster="placeholderSources[type]"
    >
    </video-background>
    <img src="@/assets/img/map-components/slider-backdrop.png" alt="backdrop" class="slider-backdrop" v-if="type==='tablet-v'" />
    <div class="section-header header">
      <typography
        is="h2"
        :mode="typographyMode.title_map_participate"
        :font="fonts.jostVariable"
        color=" #FFEECC"
        class="header__title"
      >
        Два пути участия в исповеди
      </typography>
      <typography is="span" :font="fonts.jostVariable" class="header__description">
        Направо пойдешь играючи, налево — как обычно.
      </typography>
      <div class="anchor" id="format"/>
    </div>
    <div class="ways-area">
      <way-card>
        <template #title>
          Сказ
        </template>
        <template #description>
          Классические доклады с концентрированным материалом
          для максимального погружения в тему.
        </template>
      </way-card>
      <way-card class="second-card">
        <template #title>
          Игра
        </template>
        <template #description>
          Интерактивный путь с дебатами, интервью, включением
          зрителей и двумя играми в прямом эфире.
        </template>
      </way-card>
    </div>
  </div>
</template>

<script>
import Typography from '@/components/Primitives/Typography'
import WayCard from '@/components/sections/ParticipateWays/WayCard'
import { typographyMode } from '@/themes/primitivesModes'
import fonts from '@/themes/fonts'
import { useBreakpoints } from '@/utils/useBreakpoints'

export default {
  name: 'ParticipateWaysSection',
  components: {
    Typography,
    WayCard
  },
  data () {
    return {
      fonts,
      typographyMode,
      isPlayed: false,
      placeholderSources: {
        desktop: '/participate-ways-animation.jpg',
        'tablet-h': '/participate-ways-animation-tablet.jpg',
        'tablet-v': '/participate-ways-animation-tablet.jpg'
      },
      videoYOffsets: {
        desktop: 3250,
        'tablet-h': 3450,
        'tablet-v': 3600
      }
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    stopVideo () {
      this.$refs.raven.player.pause()
      this.$refs.raven.player.currentTime = this.$refs.raven.player.duration
      this.isPlayed = true
    },
    handleScroll () {
      if (window.scrollY >= this.videoYOffsets[this.type] && !this.isPlayed && !this.type.includes('mobile')) {
        this.$refs.raven.player.play()
      }
    }
  },
  setup () {
    const type = useBreakpoints()
    return { type }
  }
}
</script>

<style lang="scss" scoped>
  .participate-ways-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 1098px;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    background-color: transparent;
  }

  .raven-background{
    object-fit: fill;
    overflow: hidden;
    width: 100%;
    z-index: 1;
    top: 2720px;
    height: 1827px;
    position: absolute !important;
  }

  .section-header {
    position: relative;
    margin-top: 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 1160px;
    margin-bottom: 250px;
    z-index: 2;
  }
  .header__description {
    width: 660px;
    text-align: left;
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }

  .header__title {
    text-align: left;
    text-transform: uppercase;
  }

  .ways-area {
    width: 1020px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    z-index: 2;
  }

  .anchor{
    position: absolute;
    top: -100px;
    width: 0;
    height: 0;
  }

  @media (max-width: 1919px){
    .section-header {
      width: 1013px;
      margin-top: 14px;
      margin-bottom: 170px;
    }
    .header__description {
      text-align: right;
    }
    .ways-area {
      width: 1013px;
    }
    .participate-ways-section {
      height: 880px;
    }

    .raven-background{
      top: 3140px;
      min-height: 1540px;
      min-width: 1500px;
      max-height: 1540px;
      max-width: 1500px;
      height: 100% !important;
      width: 100% !important;
      transform: translateX(-30px);
    }
  }

  @media (max-width: 1132px) {
    .section-header {
      margin-top: 50px;
      width: 654px;
      margin-bottom: 270px;
    }

    .ways-area {
      width: 650px;
    }
    .participate-ways-section {
      height: 817px;
    }

    .raven-background{
      min-height: 890px;
      min-width: 744px;
      max-height: 890px;
      max-width: 744px;
      height: 100% !important;
      top: 3712px;
      width: 100% !important;
      transform: translateX(0);
    }
    .slider-backdrop{
      display: block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 900px;
      top: 2812px;
    }

  }

  @media (max-width: 743px) {
    .ways-area {
      width: 301px;
      -webkit-flex-direction: column;
      flex-direction: column;

      .second-card {
        -webkit-align-self: flex-end;
        align-self: flex-end;
        margin-top: 89px;
      }
    }
    .header {
      margin-bottom: 36px;
      margin-top: 90px;
      width: 310px;

      &__title {
        width: 240px;
        line-height: 31px;
        margin-bottom: 4px;
      }

      &__description {
        width: 240px;
        text-align: left;
        -webkit-align-self: flex-start;
        align-self: flex-start;
      }
    }
    .participate-ways-section {
      height: 746px;
      background-image: url("https://storage.yandexcloud.net/cg-project-confession/participate-ways-phone");
    }
  }
</style>
