<template>
  <li class="navbar__item custom-cursor">
    <button @click="handleClick" class="custom-cursor">
      <slot></slot>
    </button>
  </li>
</template>

<script>
export default {
  name: 'NavbarItem',
  props: {
    anchor: String,
    route: String
  },
  methods: {
    handleClick () {
      if (this.route) {
        return this.$router.push(this.route)
      }
      if (!this.anchor) return

      this.$router.push('/')
      setTimeout(() => {
        document.querySelector(`#${this.anchor}`).scrollIntoView({ behavior: 'smooth', alignToTop: true })
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
  .navbar__item {
    transition: .2s border;
    list-style: none;
    width: fit-content;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom: 2px solid white;
    }

    button{
      font-family: 'Jost Variable';
      text-transform: uppercase;
      font-size: 18px;
      background-color: transparent;
      border: none;
      color: #FAFFF6;
    }
  }
  button {
    font-family: 'Jost Variable';
    text-transform: uppercase;
    font-size: 18px;
    background-color: transparent;
    border: none;
    color: #FAFFF6;
  }

  @media(max-width:1919px) {
    .navbar__item {
      border-bottom: 4px solid transparent;

      &:hover {
        border-bottom: 4px solid white;
      }

      button {
        font-size: 24px;
      }
    }
  }
</style>
