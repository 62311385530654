<template>
  <div class="threes">
    <img
      :style="threeStyle('right-3')"
      class="tree-right-3"
      src="https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-3.png"
      alt="tree" />

    <img
      :style="threeStyle('right-2')"
      class="tree-right-2"
      src="https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-2.png"
      alt="tree" />
    <Particles
      :id="`tsparticles-right`"
      class="particles"
      :style="threeStyle('right-2')"
      :particlesInit="particlesInit"
      :options="particlesConfig"
      v-if="type === 'desktop' || type === 'tablet-h'" />
    <img
      :style="threeStyle('right-1')"
      class="tree-right-1"
      src="https://storage.yandexcloud.net/cg-project-confession/tree/tree-right-1.png"
      alt="tree" />
  </div>
</template>
<script>
import { loadFull } from 'tsparticles'
import particlesConfig from '@/configs/fireflies'
import { useBreakpoints } from '@/utils/useBreakpoints'

export default {
  name: 'ThreesRight',
  props: {
    scrollPercent: {
      type: Number
    }
  },
  data: () => ({
    particlesConfig
  }),
  setup () {
    const type = useBreakpoints()
    return { type }
  },
  methods: {
    threeStyle (side) {
      const firstLayerSizeScale = 0.001
      const secondLayerSizeScale = 0.003
      const thirdLayerSizeScale = 0.005

      const firstLayerPositionScale = 0.4
      const secondLayerPositionScale = 0.25
      const thirdLayerPositionScale = 0.2

      const firstLayerblurScale = 0.08
      const secondLayerblurScale = 0.05
      const position = {
        'left-1': {
          size: 1 + this.scrollPercent * thirdLayerSizeScale,
          position: -1 - this.scrollPercent * thirdLayerPositionScale,
          blur: this.scrollPercent * firstLayerblurScale
        },
        'left-2': {
          size: 1 + this.scrollPercent * secondLayerSizeScale,
          position: -1 - this.scrollPercent * secondLayerPositionScale,
          blur: this.scrollPercent * secondLayerblurScale
        },
        'left-3': {
          size: 1 + this.scrollPercent * firstLayerSizeScale,
          position: -1 - this.scrollPercent * firstLayerPositionScale
        },
        'right-1': {
          size: 1 + this.scrollPercent * thirdLayerSizeScale,
          position: 0 - this.scrollPercent * thirdLayerPositionScale,
          blur: this.scrollPercent * firstLayerblurScale
        },
        'right-2': {
          size: 1 + this.scrollPercent * secondLayerSizeScale,
          position: 0 - this.scrollPercent * secondLayerPositionScale,
          blur: this.scrollPercent * secondLayerblurScale
        },
        'right-3': {
          size: 1 + this.scrollPercent * firstLayerSizeScale,
          position: 0 - this.scrollPercent * firstLayerPositionScale
        }
      }
      return `${side.includes('left') ? 'left' : 'right'}: ${position[side].position}%;
     filter: blur(${position[side]?.blur || 0}px);
      `
    },
    async particlesInit (engine) {
      await loadFull(engine)
    }
  }
}
</script>
<style lang="scss" scoped>
.particles {
  width: 40%;
  height: 100vh;
  position: absolute;
  z-index: 1;
}
img {
  height: 100vh;
  position: absolute;
  transition: all 150ms ease-out;
  will-change: all;

  @include tablet-lg {
    display: none;
  }
  @include tablet-sm {
    display: none;
  }
  @include mobile {
    display: none;
  }

  @keyframes rotate-right {
    50% {
      transform: rotate(0.2deg);
    }
  }
  @keyframes rotate-left {
    50% {
      transform: rotate(-0.2deg);
    }
  }
}
.tree-right-1,
.tree-right-2,
.tree-right-3 {
  right: 0;
  bottom: 0;
  top: 0;
  transform-origin: bottom right;
  z-index: 1;
}

.tree-right-1 {
  animation: rotate-left infinite ease-in-out 4s 1s;
}
.tree-right-2 {
  animation: rotate-left infinite ease-in-out 4s 1.5s;
}
.tree-right-3 {
  animation: rotate-left infinite ease-in-out 4s 2s;
}
</style>
